// React
import React from 'react';
import classNames from 'classnames';
// Material UI and Icons
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
// Utils
import { APP_UTILS } from '../config/app.utils';

const TableTotals = (props) => {
	const { data } = props;
	const tableColumns = data && data.tableColumns;
	const rowData = data && data.rowData;

	return (
		<Paper className="SmallTableWrapper" elevation={4}>
			<TableContainer className="TableContainer">
				<Table stickyHeader>
					<TableHead className="TableHeader">
						<TableRow>
							{tableColumns.map((column) => (
								<TableCell
									className="TableHeaderCell"
									key={column.id}
									align={column.align}
									style={{
										width: column.width,
										minWidth: column.minWidth,
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rowData.length && rowData.map((item, index) => (
							<TableRow
								key={`id_${item.operation}`}
								className={classNames({
									TableRowEven: index % 2,
									TableRowOdd: !(index % 2),
								})}
							>
								<TableCell align="center">
									{item.operation}
								</TableCell>
								<TableCell align="center">
									{APP_UTILS.formatCurrency(
										item.total
									)}
								</TableCell>
							</TableRow>
						))
						}
						{!rowData.length && 
							<TableRow
								key="id_balance"
								className={classNames({
									TableRowEven: true,
								})}
							>
								<TableCell align="center">
									{APP_UTILS.formatCurrency(
										rowData
									)}
								</TableCell>
							</TableRow>
						}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}

export default TableTotals;