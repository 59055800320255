const ExportToCSV = (header, data) => {
	// Build header
	let csv = `\ufeff${header.join(',')}\n`; // added \ufeff to encoding uft-8
	const keys = Object.keys(data[0]);

	// Add the rows
	data.forEach((obj) => {
		csv += `${keys.map(k => obj[k]).join(',')}\n`;
	});

	// Export Data
	const year = new Date().getFullYear();
	let month = new Date().getMonth() + 1;
	let day = new Date().getDate();
	month = ( month >= 10) ? month : `0${month}`;
	day = ( day >= 10) ? day : `0${day}`;
	const date = `${day}_${month}_${year}`;

	const fileTitle = `PDV_${date}.csv`;
	const exportedFileName = fileTitle || 'export.csv';

	const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	if (navigator.msSaveBlob) { // IE 10+
		navigator.msSaveBlob(blob, exportedFileName);
	} else {
		const link = document.createElement("a");
		if (link.download !== undefined) { // feature detection
			// Browsers that support HTML5 download attribute
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", exportedFileName);
			link.style.visibility = 'hidden';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}
};

export default ExportToCSV;