export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SELECT_OPERATOR = 'SELECT_OPERATOR';
export const SET_RAFFLE_DATA = 'SET_RAFFLE_DATA';
export const CLEAR_RAFFLE_DATA = 'CLEAR_RAFFLE_DATA';

export const setUserData = (text) => ({
	type: SET_USER_DATA,
	payload: text,
});

export const clearUserData = () => ({
	type: CLEAR_USER_DATA,
	payload: '',
});

export const selectOperator = (operatorData) => ({
	type: SELECT_OPERATOR,
	payload: operatorData,
});

export const setRaffleData = (obj) => ({
	type: SET_RAFFLE_DATA,
	payload: obj,
});

export const clearRaffleData = () => ({
	type: CLEAR_RAFFLE_DATA,
	payload: '',
});
