/* eslint-disable no-useless-constructor */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

// User Profile
import React from 'react';
import {
	Button,
	TextField,
	Collapse,
	LinearProgress,
	Paper,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
	ThemeProvider,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import { setUserData, clearUserData } from '../redux/action_types';
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';
import { APP_UTILS } from '../config/app.utils';

// Definition
class ChangePassword extends React.Component {
	constructor(props) {
		super(props);

		// Define initial state
		this.initialState = {
			formProcess: false,
			oldPassword: '',
			newPassword: '',
			newPasswordConfirmation: '',
			token: this.props.userData && this.props.userData.token,
			error: {
				type: 'info',
				message: '',
			},
		};

		this.state = this.initialState;
		this.userData = props.userData;

		this.oldPasswordInput = null;
		this.newPasswordInput = null;
		this.newPasswordConfirmationInput = null;
	}

	componentDidMount() {
		// Check token and login status
		if (!this.userData || !this.props.userData.email || !this.state.token) {
			this.props.history.push('/login');
		}
	}

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'newPasswordConfirmation':
				if (e.key === 'Enter') {
					this.processForm();
				}
				break;

			default:
				break;
		}
	};

	handleContinue = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.processForm();
	};

	handleCancel = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.history.push('/login');
	};

	handleLogout = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		this.props.history.push('/login');
	};

	processErrors =(errorCode) => {
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}
	
		this.setState({ formProcess: false });
	};

	processResult = (data) => {
		if (!data.message) {
			this.setMessage('error', APP_TEXTS.INVALID_DATA);
			this.setState({ formProcess: false });
			return;
		}

		// Set up data
		const userData = ({
			firstLogin: false,
		});

		// Clear data and close login
		this.setMessage('success', APP_TEXTS.LOGIN_SUCCESSFUL);
		setTimeout(() => {
			this.props.setUserData(userData);
			this.props.history.push('./');
		}, 1000);
	};

	setErrorMessageOnForm = (errorType, errorText) => {
		this.setMessage(errorType, errorText);
		this.setState({ formProcess: false });
	};

	setMessage = (type, message) => {
		if (type && message) {
			// Set Error
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	async processForm() {
		// Double check that the login process is not already running
		if (this.state.formProcess) {
			return false;
		}

		// Read user and password
		if (!this.state.oldPassword) {
			this.oldPasswordInput.focus();
			this.setMessage('info', APP_TEXTS.ENTER_OLD_PASSWORD);
			return false;
		}
		if (!this.state.newPassword) {
			this.newPasswordInput.focus();
			this.setMessage('info', APP_TEXTS.ENTER_NEW_PASSWORD);
			return false;
		}
		if (!this.state.newPasswordConfirmation) {
			this.newPasswordConfirmationInput.focus();
			this.setMessage('info', APP_TEXTS.ENTER_NEW_PASSWORD_CONFIRMATION);
			return false;
		}
		if (this.state.newPassword !== this.state.newPasswordConfirmation) {
			this.newPasswordConfirmationInput.focus();
			this.setMessage('info', APP_TEXTS.PASSWORD_DO_NOT_MATCH);
			return false;
		}

		// Set Form on process and clear previous error
		this.setState({
			formProcess: true,
			error: { type: 'info', message: '' },
		});

		// Get data
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_CHANGE_PASSWORD;
		requestUrl += `?hash=${new Date().getTime()}`;

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.state.token}`,
		});

		// Send Data
		const data = {
			old_password: this.state.oldPassword,
			new_password: this.state.newPassword,
		};

		await fetch(requestUrl, {
			method: 'POST',
			body: JSON.stringify(data),
			headers,
		})
			.then((response) => response.json())
			.then((returnedData) => {
				// Check status
				if (returnedData.errors && returnedData.errors.length) {
					const errorCode = returnedData.errors[0] && returnedData.errors[0].code ? returnedData.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}
				this.processResult(returnedData);
			}, () => {
				// Set Error
				this.setMessage('error', APP_TEXTS.CONNECTION_ERROR);
				this.setState({ formProcess: false });
			})
			.catch(() => {
				// Check if autologin
				// Set Error
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				this.setState({ formProcess: false });
			});

		return true;
	}

	// Logo out / clear all user data
	logoutUser() {
		// Clear User Data
		this.setState(this.initialState);

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		// Update store
		this.props.clearUserData();
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Paper
					className="Home Main CenteredX no-border"
					variant="elevation"
					elevation={0}
					style={{ backgroundColor: 'transparent' }}
				>
					<Card className="Card Login CenteredXY" variant="outlined" elevation={4}>
						<CardHeader
							title="Cambiar contraseña"
							subheader={
								this.userData.firstLogin
									? APP_TEXTS.CHANGE_PASSWORD_NEEDED
									: APP_TEXTS.ENTER_NEW_PASSWORD
							}
							avatar={<LockIcon />}
						/>
						<CardContent>
							<div>
								<TextField
									autoFocus
									margin="dense"
									id="oldPassword"
									label="Contraseña anterior"
									type="password"
									variant="outlined"
									fullWidth
									value={this.state.oldPassword}
									inputRef={(input) => {
										this.oldPasswordInput = input;
									}}
									onKeyPress={this.onKeyPress}
									onChange={(event) =>
										this.setState({
											oldPassword: event.currentTarget.value,
										})
									}
								/>
								<TextField
									autoFocus
									margin="dense"
									id="newPassword"
									label="Nueva contraseña"
									type="password"
									variant="outlined"
									fullWidth
									value={this.state.newPassword}
									inputRef={(input) => {
										this.newPasswordInput = input;
									}}
									onKeyPress={this.onKeyPress}
									onChange={(event) =>
										this.setState({
											newPassword: event.currentTarget.value,
										})
									}
								/>
								<TextField
									margin="dense"
									id="newPasswordConfirmation"
									label="Confirmación"
									type="password"
									variant="outlined"
									fullWidth
									value={this.state.newPasswordConfirmation}
									inputRef={(input) => {
										this.newPasswordConfirmationInput = input;
									}}
									onKeyPress={this.onKeyPress}
									onChange={(event) =>
										this.setState({
											newPasswordConfirmation: event.currentTarget.value,
										})
									}
								/>
								<Collapse in={this.state.error.message !== ''}>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								{this.state.formProcess && <LinearProgress />}
							</div>
						</CardContent>
						<CardActions>
							<Grid container className="relative h100">
								<Grid item xs={6} className="relative h100 alignSelfCenter">
									{!this.userData.firstLogin && (
										<Button
											type="button"
											onClick={this.handleCancel}
											color="secondary"
											disabled={this.userData.firstLogin || this.state.formProcess}
										>
											{APP_TEXTS.CANCEL}
										</Button>)}
								</Grid>
								<Grid
									item
									xs={6}
									className="relative h100 alignSelfCenter textAlignRight"
								>
									<Button
										type="submit"
										className="Button ButtonHigh greenGradient"
										onClick={this.handleContinue}
										disabled={this.state.formProcess}
									>
										{APP_TEXTS.MESSAGE_CHANGE_PASSWORD}
									</Button>
								</Grid>
							</Grid>
						</CardActions>
					</Card>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChangePassword));
