/* eslint-disable new-cap */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import es from 'date-fns/locale/es';
import {
	Button,
	Paper,
	Grid,
	GridListTile,
	GridListTileBar,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	ThemeProvider,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setRaffleData } from '../redux/action_types';
import APP_CONFIG from '../config/app.config';
import { APP_UTILS } from '../config/app.utils';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';

// Definition
class BuyTickets extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageSelected: 0,
			raffleMessage: '',
			serverData: [],
			raffleData: [],
		};

		// Get User Data
		this.userData = {};

		this.itemsPerPage = 5;
		this.dateToday = new Date();
		this.selectedDate = new Date();
		this.selectedDate.setHours(this.dateToday.getHours(), this.dateToday.getMinutes(), this.dateToday.getSeconds());
		this.selectedToDate = new Date();
		this.selectedToDate.setHours(23,59,59);
		this.selectedDateMin = new Date();
		this.selectedDateMin.setDate(this.dateToday.getDate());
		/* this.selectedDateMax = new Date();
		this.selectedDateMax.setDate(this.dateToday.getDate() + 15); */

		this.tableColumns = [
			{
				id: 'name',
				label: 'Nombre del Sorteo',
				width: '18%',
				minWidth: '18%',
				align: 'center',
			},
			{
				id: 'date',
				label: 'Fecha',
				width: '12%',
				minWidth: '12%',
				align: 'center',
			},
			{
				id: 'time',
				label: 'Hora',
				width: '10%',
				minWidth: '10%',
				align: 'center',
			},
			{
				id: 'closePurchase',
				label: 'Cierre de Compra',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
			{
				id: 'status',
				label: 'Estado',
				width: '20%',
				minWidth: '20%',
				align: 'center',
			},
			{
				id: 'value',
				label: 'Valor',
				width: '10%',
				minWidth: '10%',
				align: 'center',
				format: (value) => value.toFixed(2),
			},
			{
				id: 'actions',
				label: 'Acciones',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
		];

		this.raffleStatus = [
			'Sorteo no disponible',
			'Habilitado para compra',
			'Sorteo Finalizado',
			'Sin cartones disponibles',
		];

		this.handlePageChange = this.handlePageChange.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.handleQuery = this.handleQuery.bind(this);
	}

	componentDidMount() {
		// get info in Store Redux
		const date = this.props.raffleData && this.props.raffleData.selectedDate; 
		if (date && date.from && date.to) {
			this.selectedDate = date.from;
			this.selectedToDate = date.to;
		}

		this.handleQuery();
	}

	async handleQuery() {
		// Get User Data
		this.userData = this.props.userData;
		this.setState({ 
			raffleData: [],
			raffleMessage: APP_TEXTS.SEARCHING,
		});

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_FUTURE_PLAYS;

		const dateFrom = this.calcTime(this.selectedDate);
		const dateTo = this.calcTime(this.selectedToDate);

		const params = JSON.stringify({
			"date_from": dateFrom,
			"date_to": dateTo,
			"operator_id": this.userData.operatorId,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.future_plays && resp.future_plays.length) {
					this.setState({
						serverData: resp.future_plays,
						raffleMessage: '',
					});

					this.processResult();
				} else if(resp.future_plays && resp.future_plays.length === 0) {
					this.setState({ 
						raffleData: [],
						raffleMessage: APP_TEXTS.NO_RAFFLE_FOUND,
					 });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					raffleMessage: `Error: ${error}`,
				});
			});
	}

	handlePageChange(e, page) {
		this.setState({
			pageSelected: page,
		});
	}

	handleDateChange(e) {
		try {
			const dateToday = new Date();
			const toDate = new Date(e);
			
			if (e.getTime() > dateToday.getTime()) {
				this.selectedDate = e;
				this.selectedDate.setHours(0, 1, 0);
			} else {
				this.selectedDate = new Date();
				this.selectedDate.setHours(dateToday.getHours(), dateToday.getMinutes(), dateToday.getSeconds());
			}

			this.selectedToDate = toDate;
			this.selectedToDate.setHours(23, 59, 59);

			setTimeout(() => {
				this.handleQuery();				
			}, 500);
		} catch (error) {
			console.log(error);
		}
	}

	calcTime = (date) => {
		const offset = -3 * 2;
		const hourByMilli = 3600000;
		const millisecondsInMinute = 60000;
	
		const utc = date.getTime() + (date.getTimezoneOffset() * millisecondsInMinute);
		const newDateWithOffset = new Date(utc + (hourByMilli * offset));
	
		return newDateWithOffset.toISOString();
	};

	processResult = () => {
		const { serverData } = this.state;
		const parsedData = [];

		for (let index = 0; index < serverData.length; index++) {
			const raffle = serverData[index];
			const parseCloseDate = Date.parse(raffle.close_purchase);
			const parseStartDate = Date.parse(raffle.start_time);
			const parseStartPurchaseDate = Date.parse(raffle.start_purchase);

			const year = new Date(parseStartDate).getFullYear();
			let month = new Date(parseStartDate).getMonth() + 1;
			let day = new Date(parseStartDate).getDate();
			month = ( month >= 10) ? month : `0${month}`;
			day = ( day >= 10) ? day : `0${day}`;
			const date = `${year}-${month}-${day}`;

			let h = new Date(parseStartDate).getHours();
			let m = new Date(parseStartDate).getMinutes();
			let s = new Date(parseStartDate).getSeconds();
			h = ( h >= 10) ? h : `0${h}`;
			m = ( m >= 10) ? m : `0${m}`;
			s = ( s >= 10) ? s : `0${s}`;
			const time = `${h}:${m}:${s}`;

			let cpHours = new Date(parseCloseDate).getHours();
			let cpMinutes = new Date(parseCloseDate).getMinutes();
			let cpSeconds = new Date(parseCloseDate).getSeconds();
			cpHours = ( cpHours >= 10) ? cpHours : `0${cpHours}`;
			cpMinutes = ( cpMinutes >= 10) ? cpMinutes : `0${cpMinutes}`;
			cpSeconds = ( cpSeconds >= 10) ? cpSeconds : `0${cpSeconds}`;
			const cpTime = `${cpHours}:${cpMinutes}:${cpSeconds}`;

			const now = Date.now();
			const status = (now >= parseStartPurchaseDate && now <= parseCloseDate) ? 1 : 0;

			const data = {
				id: raffle.match_id,
				name: raffle.name,
				date,
				time,
				closePurchase: cpTime,
				value: raffle.card_value,
				status,
			};

			if (raffle.close_purchase !== null ) {
				parsedData.push( data );
			}
		}

		if (parsedData.length) {
			const sortedData = parsedData.sort((a, b) => {
				const aDate = new Date(a.date).getTime();
				const bDate = new Date(b.date).getTime();
				return aDate - bDate;
			});

			this.setState({
				raffleData: sortedData,
			});
		}

	};

	processErrors = (errorCode) => {
		// Check for errors
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.message) {
			this.setMessage(error.message);
		}
	};

	setMessage = (message) => {
		if (message) {
			// Set Error
			// const typeMessage = (type) ? `${type}: ` : ''; 
			this.setState({
				// raffleMessage: `${typeMessage} ${message}`,
				raffleMessage: message,
			});
		}
	};

	selectRaffle = (e) => {
		const raffleData = this.state.raffleData;
		if (e.currentTarget && raffleData.length) {
			const id = Number(e.currentTarget.id);
			const data = raffleData[id];

			if (data) {
				const selectedRaffle = {
					selected: {
						id: data.id,
						name: data.name,
						date: data.date,
						time: data.time,
						status: data.status,
						value: data.value,
					},
					all: raffleData,
					selectedDate: {
						from: this.selectedDate,
						to: this.selectedToDate,
					},
				};
				// set info in Store Redux
				this.props.setRaffleData(selectedRaffle);
	
				const path = `./tickets/:${e.currentTarget.id}`;
				return this.props.history.push(path);
			}
		}
		return false;
	};

	goToHome = () => {
		const path = '/';
		this.props.history.push(path);
	};

	render() {
		const { raffleData, pageSelected, raffleMessage } = this.state;
		const { selectedDate, selectedDateMin, itemsPerPage, tableColumns } = this;
		return (
			<ThemeProvider theme={theme}>
				<Paper className="Main InternalPage CenteredX" variant="outlined" elevation={12}>
					<Grid container className="MainGrid h100">
						<Grid xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{
										backgroundImage: "url('./images/buy_card.jpg')",
									}}
								/>
								<GridListTileBar title={APP_TEXTS.BUY_CARD} titlePosition="top" />
							</GridListTile>
						</Grid>
						<Grid xs={12} className="SectionBody">
							<Paper className="Body mh100" elevation={0}>
								<Grid container className="BodyTitle">
									<Grid
										xs={6}
										className="relative h100"
										style={{ padding: '1.2em 0' }}
									>
										<Typography variant="h5" component="h5">
											Listado de Sorteos Próximos
										</Typography>
									</Grid>
									<Grid xs={6}>
										<MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
											<Grid container justify="flex-end">
												<DatePicker
													disablePast
													autoOk
													inputVariant="outlined"
													label="Seleccione Fecha"
													format="dd/MM/yyyy"
													orientation="landscape"
													size="small"
													okLabel=''
													cancelLabel=''
													emptyLabel=''
													invalidLabel=''
													invalidDateMessage=''
													minDate={selectedDateMin}
													value={selectedDate}
													onChange={this.handleDateChange}
												/>
											</Grid>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Paper className="TableWrapper" elevation={4}>
									<TableContainer className="TableContainer">
										<Table stickyHeader aria-label="sticky table dense">
											<TableHead className="TableHeader">
												<TableRow>
													{tableColumns.map((column) => (
														<TableCell
															className="TableHeaderCell"
															key={column.id}
															align={column.align}
															style={{
																width: column.width,
																minWidth: column.minWidth,
															}}
														>
															{column.label}
														</TableCell>
													))}
												</TableRow>
											</TableHead>
											<TableBody>
												{raffleData.length ? (
													raffleData.map((raffle, index) => {
														if (
															index >= pageSelected * itemsPerPage &&
															index < (pageSelected + 1) * itemsPerPage
														) {
															return (
																<TableRow
																	key={raffle.id}
																	className={classNames({
																		TableRowEven: index % 2,
																		TableRowOdd: !(index % 2),
																	})}
																>
																	<TableCell
																		component="th"
																		scope="row"
																		align="center"
																	>
																		{raffle.name}
																	</TableCell>
																	<TableCell
																		component="th"
																		scope="row"
																		align="center"
																	>
																		{raffle.date}
																	</TableCell>
																	<TableCell align="center">
																		{raffle.time}
																	</TableCell>
																	<TableCell align="center">
																		{raffle.closePurchase}
																	</TableCell>
																	<TableCell align="left">
																		{
																			this.raffleStatus[
																				raffle.status
																			]
																		}
																	</TableCell>
																	<TableCell align="right">
																		{APP_UTILS.formatCurrency(
																			raffle.value
																		)}
																	</TableCell>
																	<TableCell align="center">
																		<Button
																			className={classNames({
																				Button,
																				greenGradient:
																					raffle.status === 1,
																				greyGradient:
																					raffle.status !== 1,
																			})}
																			disabled={
																				raffle.status !== 1
																			}
																			id={index}
																			onClick={this.selectRaffle}
																		>
																			Elegir
																		</Button>
																	</TableCell>
																</TableRow>
															);
														}
														return null;
													})
												) : (
													<TableRow>
														<TableCell
															colSpan={7}
															component="th"
															scope="row"
															align="center"
															variant="head"
														>
															{raffleMessage}
														</TableCell>
													</TableRow>
												)}
											</TableBody>
										</Table>
									</TableContainer>
									{raffleData.length > 0 && 
										(<TablePagination
											className="Pagination"
											rowsPerPageOptions={[6]}
											component="div"
											count={raffleData.length}
											rowsPerPage={itemsPerPage}
											page={pageSelected}
											onChangePage={this.handlePageChange}
											onChangeRowsPerPage={this.handlePageChange}
											labelRowsPerPage={APP_TEXTS.TABLE_RAFFLES_PER_PAGE}
											labelDisplayedRows={({ from, to, count }) => (`${from}-${to} ${APP_TEXTS.TABLE_DISPLAYED_ROWS} ${(count !== -1) ? count : to}`)}
										/>)}
								</Paper>
							</Paper>
						</Grid>
						<Grid xs={12} className="SectionActions">
							<Paper className="Actions h100" elevation={0}>
								<Button className="Button ButtonHigh redGradient" onClick={this.goToHome}>
									{APP_TEXTS.GO_TO_MENU}
								</Button>
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
	raffleData: state.raffleData,
});

const mapDispatchToProps = {
	setRaffleData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BuyTickets));
