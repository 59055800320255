/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable operator-linebreak */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/prop-types */
import React from 'react';
import {
	Button,
	Paper,
	Grid,
	GridListTile,
	GridListTileBar,
	Box,
	Card,
	CardContent,
	Typography,
	TextField,
	LinearProgress,
	Collapse,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContentText,
	CircularProgress,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ThemeProvider,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import APP_CONFIG from '../config/app.config';
import { APP_UTILS } from '../config/app.utils';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';

const styleBigContainer = {
	display: 'flex',
	height: '70%',
	margin: '0',
};

const styleButtonMessage = {
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'center',
};

const styleFlexColumn = {
	display: 'flex',
	flexDirection: 'column',
	paddingTop: '1%',
};

const successDialog = {
	background: 'rgba(168, 177, 43, 1)',
	backgroundImage: 'linear-gradient(90deg, rgba(168, 177, 43, 1) 0%, rgba(23, 152, 0, 1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const cancelDialog = {
	background: 'rgb(201,28,123)',
	backgroundImage: 'linear-gradient(0deg, rgba(201,28,123,1) 0%, rgba(228,58,43,1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const iconTitle = {
	fontSize: '3rem',
};

const bottomBar = {
	padding: '0',
	display: 'flex',
	justifyContent: 'space-between',
};

const styleSelect = {
	minWidth: '25%',
	maxWidth: '25%',
	marginRight: '1%',
};

class Cashout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pin: '',
			typeDoc: 'DNI',
			doc: '',
			amount: 0,
			casinoUserId: '',
			email: '',
			validated: false,
			validationInProcess: false,
			openDialog: false,
			openDialogConfir: false,
			buttonMessage: APP_TEXTS.CASHOUT_CREDIT,
			styleDialog: successDialog,
			messageDialog: '',
			titleDialog: '',
			error: {
				type: 'info',
				message: '',
			},
		};
	}

	handleDocument = (e) => {
		this.clearError();
		const newDoc = e.target.value;
		if (Number(newDoc) >= 0) {
			if (newDoc) {
				this.setState({ doc: newDoc });
			} else {
				this.setState({ doc: '' });
			}
		} else {
			this.setMessage('error', APP_TEXTS.REQUIRED_INVALID_DOC);
		}
	};

	handlePin = (e) => {
		if (e.target.value) {
			this.setState({ pin: e.target.value });
		} else {
			this.setState({ pin: '' });
		}
	};

	handleValidation = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.processValidation();
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	setMessage = (type, message) => {
		if (type && message) {
			this.setState({
				error: { type, message },
			});
		}
	};

	handlePayPrize = (e) => {
		if (this.state.prizePayed) {
			return;
		}
		e.preventDefault();
		e.stopPropagation();
		this.setState({ openDialogConfir: true });
	};

	handleDialogOk = () => {
		if (!this.state.pin) {
			this.setMessage('error', APP_TEXTS.REQUIRED_PIN);
		} else {
			this.confirmCashout();
		}

		this.setState({ openDialogConfir: false });
	};

	handleDialogCancel = () => {
		this.setState({ openDialogConfir: false });
		this.clearError();
	};

	handleMessageOk = () => {
		this.setState({ openDialog: false });
		this.goToBack();
	};

	goToMenu = () => {
		const path = '/';
		this.resetData();
		this.props.history.push(path);
	};

	goToBack = () => {
		this.resetData();
	};

	processErrors = (errorCode) => {
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}

		this.setState({ validationInProcess: false });
	};

	processResult = (resp) => {
		const state = resp.transaction_state;

		switch (state) {
			case 'TS_SUCCESS':
				this.setState({ 
					messageDialog: APP_TEXTS.SUCCESS_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: successDialog,
					openDialog: true,
				});
				break;

			case 'TS_CANCEL':
				this.setMessage('warning', APP_TEXTS.CANCEL_TRANSACTION);
				this.setState({ 
					messageDialog: APP_TEXTS.CANCEL_TRANSACTION,
					titleDialog: APP_TEXTS.CANCEL_TITLE_MESSAGE,
					styleDialog: cancelDialog,
					openDialog: true,
				});
				break;

			case 'TS_CLOSE':
				this.setState({ 
					messageDialog: APP_TEXTS.CLOSURE_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: successDialog,
					openDialog: true,
				});
				break;

			default:
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				break;
		}
	};

	handleChangeSelect = (e) => {
		if (e.target.value) {
			this.setState({ typeDoc: e.target.value });
		}
	};

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'doc':
				if (e.key === 'Enter' && this.state.doc !== '') {
					this.processValidation();
				}
				break;

			case 'pin':
				if (e.key === 'Enter' && this.state.pin !== '') {
					this.processValidation();
				}
				break;

			default:
				break;
		}
	};

	resetData = () => {
		this.setState({
			pin: '',
			typeDoc: 'DNI',
			doc: '',
			amount: 0,
			casinoUserId: '',
			email: '',
			validated: false,
			validationInProcess: false,
			openDialog: false,
			openDialogConfir: false,
			buttonMessage: APP_TEXTS.CASHOUT_CREDIT,
			styleDialog: successDialog,
			messageDialog: '',
			titleDialog: '',
			error: {
				type: 'info',
				message: '',
			},
		});
	};

	emulateResult = () => {
		const resp = {
			casino_user_id: 'casino_user_id',
	    amount: 10.25,
	    email: 'email@format.com',
	    validationInProcess: false,
		};

		this.setState({
			error: { type: 'info', message: '' },
			validated: true,
			amount: resp.amount,
			email: resp.email,
			casinoUserId: resp.casino_user_id,
		});

		return true;
	};


	async processValidation() {
		// check that process is not already running
		if (this.state.validationInProcess) {
			return false;
		}

		if (!this.state.doc) {
			this.setMessage('error', APP_TEXTS.REQUIRED_DOC);
			return false;
		}

		if (!this.state.pin) {
			this.setMessage('error', APP_TEXTS.REQUIRED_PIN);
			return false;
		}

		// Set Form on process and clear previous error
		this.setState({
			validationInProcess: false,
			error: { type: 'info', message: '' },
		});

		/* this.emulateResult();
		return true; */

		this.userData = this.props.userData;
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_VALIDATE_WITHDRAW;

		const params = JSON.stringify({
			"PIN": this.state.pin,
			"doc": this.state.doc,
			"type_doc": this.state.typeDoc,
			"operator_id": this.userData.operatorId,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp) {
					this.setState({
						error: { type: 'info', message: '' },
						validated: true,
						amount: Number(resp.amount),
						email: resp.email,
						casinoUserId: resp.casino_user_id,
					});
				} else {
					this.setState({
						error: { type: 'info', message: APP_TEXTS.TICKET_NUMBER_NOT_PRIZE },
						validationInProcess: false,
						validated: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					error: { type: 'warning', message: error },
				});
			});

		return true;
	}

	async confirmCashout() {
		this.setState({
			validationInProcess: true,
			error: { type: 'info', message: '' },
		});

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_CONFIRM_WITHDRAW;

		const params = JSON.stringify({
			"casino_user_id": this.state.casinoUserId,
    	"email": this.state.email,
    	"type_doc": this.state.typeDoc,
    	"doc": this.state.doc,
    	"PIN": this.state.pin,
			"operator_id": this.userData.operatorId,
    	"amount": this.state.amount,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.transaction_number || resp.transaction_state) {
					this.processResult(resp);
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					error: { type: 'info', message: error },
				});
			});

		return true;
	}

	render() {
		const {
			typeDoc,
			doc,
			amount,
			email,
			pin,
			casinoUserId,
			validated,
			validationInProcess,
			openDialog,
			openDialogConfir,
			buttonMessage,
			messageDialog,
			titleDialog,
			styleDialog,
		} = this.state;
		return (
			<ThemeProvider theme={theme}>
				<Paper className="Main InternalPage CenteredX" variant="outlined">
					<Grid container className="MainGrid InternalPage" elevation={4}>
						<Grid xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{ backgroundImage: "url('./images/withdraw_credit.jpg')" }}
								/>
								<GridListTileBar title={APP_TEXTS.CREDIT_WITHDRAWAL} titlePosition="top" />
							</GridListTile>
						</Grid>
						<Grid xs={12} className="SectionBody h100">
							<Paper className="Body mh100" elevation={0} style={{ marginTop: '2%' }}>
								<Grid container className="BodyTitle">
									{!validated && (
										<>
											<Grid
												xs={12}
												sm={5}
												style={{ padding: '2%', margin: 'auto' }}
											>
												<Card style={styleBigContainer}>
													<CardContent
														className="w100"
														style={{ padding: '3%', textAlign: 'center' }}
													>
														<Typography variant="h6">
															Número de Documento
														</Typography>
														<Box style={styleFlexColumn}>
															<Box>
																<FormControl variant="outlined" style={styleSelect}>
																	<InputLabel id="labelDocType">Tipo</InputLabel>
																	<Select
																		labelId="labelSelectDocType"
																		id="selectDocType"
																		value={typeDoc}
																		onChange={this.handleChangeSelect}
																		label="Tipo Doc."
																	>
																		<MenuItem value="">
																			<em>--</em>
																		</MenuItem>
																		<MenuItem value="DNI">
																			DNI
																		</MenuItem>
																		<MenuItem value="PASS">
																			Pasaporte
																		</MenuItem>
																		<MenuItem value="LC">
																			Libreta Cívica
																		</MenuItem>
																		<MenuItem value="LE">
																			Libreta de Enrolamiento
																		</MenuItem>
																	</Select>
																</FormControl>
																<TextField
																	id="doc"
																	label="Nro. Documento"
																	variant="outlined"
																	value={doc}
																	required
																	autoComplete="off"
																	onKeyPress={this.onKeyPress}
																	onChange={this.handleDocument}
																	style={{ marginRight: '1%' }}
																/>
																<Typography
																	variant="h6"
																	style={{ marginTop: '2%' }}
																>
																	Número de Pin
																</Typography>
																<TextField
																	id="pin"
																	label="Nro. Pin"
																	variant="outlined"
																	value={pin}
																	required
																	autoComplete="off"
																	onKeyPress={this.onKeyPress}
																	onChange={this.handlePin}
																	style={{ width: '78%'}}
																/>
															</Box>
															<Button
																className="ButtonHigh greenGradient"
																style={{
																	borderRadius: '2em',
																	margin: '10%',
																}}
																onClick={this.handleValidation}
															>
																{validationInProcess && <CircularProgress style={{ width: '10%', height: '10%', color: '#fff' }} />}
																{!validationInProcess && 'Validar'}
															</Button>
														</Box>
													</CardContent>
												</Card>
											</Grid>
										</>
									)}
									{validated && (
										<>
											<Grid
												xs={12}
												className="h100"
												style={{ alignSelf: 'start' }}
											>
												<Card style={styleBigContainer}>
													<CardContent>
														<Typography variant="h6" style={{ display: 'flex' }}>
															<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
																{typeDoc}:
															</Typography>
															{doc}
														</Typography>
														<Typography variant="h6" style={{ display: 'flex' }}>
															<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
																Correo Electrónico:
															</Typography>
															{email}
														</Typography>
														<Typography variant="h6" style={{ display: 'flex' }}>
															<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
																Id Casino:
															</Typography>
															{casinoUserId}
														</Typography>
														<Typography variant="h6" style={{ display: 'flex' }}>
															<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
																Monto a Retirar:
															</Typography>
															{APP_UTILS.formatCurrency(amount)}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
											<Grid
												xs={12}
												style={{ margin: '8% 0px 0px 0px' }}
											>
												<Card elevation={0}>
													<CardContent style={styleButtonMessage}>
														<Button
															id="buttonPay"
															className={classNames({
																Button,
																greenGradient: amount > 0,
																greyGradient: (amount === 0 || amount === null),
															})}
															disabled={amount === 0 || amount === null}
															style={{
																borderRadius: '2em',
																padding: '2% 0px',
															}}
															onClick={this.handlePayPrize}
														>
															{buttonMessage}
														</Button>
													</CardContent>
												</Card>
											</Grid>
										</>
									)}
								</Grid>
							</Paper>
						</Grid>
						<Grid xs={12} sm={12} className="SectionActions">
							<Paper className="Actions h100" elevation={0} style={bottomBar}>
								<Collapse
									in={this.state.error.message !== ''}
									style={{ width: '100%'}}
								>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
										style={{ padding: '0px 16px', margin: 'auto' }}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								{validated && (
									<Button className="Button redGradient" onClick={this.goToBack}>
										{APP_TEXTS.BACK}
									</Button>
								)}
								<Button className="Button redGradient" onClick={this.goToMenu}>
									{APP_TEXTS.GO_TO_MENU}
								</Button>
							</Paper>
						</Grid>
					</Grid>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialogConfir}
					>
						<LinearProgress className="linear-progress-warning" />
						<DialogTitle style={{ backgroundColor: '#221f1f', color: '#ffffff', padding: '4%' }}>
							<b>{APP_TEXTS.TITLE_MESSAGE_CONFIRMATION}</b>
						</DialogTitle>
						<DialogContentText style={{ padding: '5%' }}>
							<Typography variant="h6" gutterBottom style={{ color: '#000000' }}>
								{APP_TEXTS.CONFIRM_MESSAGE_CASHOUT}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button
								className="Button redGradient"
								autoFocus
								onClick={this.handleDialogCancel}
								color="default"
							>
								{APP_TEXTS.CANCEL}
							</Button>
							<Button
								className="Button greenGradient"
								onClick={this.handleDialogOk}
								color="default"
							>
								{APP_TEXTS.BTN_CASHOUT}
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialog}
					>
						<DialogTitle style={styleDialog}>
							<Grid style={{ display: 'flex', margin: '0%'}}>
								<Grid xs={2}>
									{(titleDialog === APP_TEXTS.SUCCESS_TITLE_MESSAGE) 
										? <CheckCircleOutlineIcon style={iconTitle} />
										: <BlockIcon style={iconTitle} />
									}
								</Grid>
								<Grid xs={10}>
									<Typography
										variant="h6"
										style={{ paddingTop: '2%' }}
									>
										{titleDialog}
									</Typography>
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContentText style={{ padding: '6%', margin: '0% 0% 6% 0%' }}>
							<Typography variant="h6" component="h6" gutterBottom style={{ color: '#000000' }}>
								{messageDialog}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button
								className="Button greenGradient"
								onClick={this.handleMessageOk}
								color="default"
							>
								{APP_TEXTS.ACCEPT}
							</Button>
						</DialogActions>
					</Dialog>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

export default withRouter(connect(mapStateToProps)(Cashout));
