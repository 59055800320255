import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#3D9F0B',
		},
		secondary: {
			main: '#f44336',
		},
	},
	overrides: {
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: '#3D9F0B',
			},
		},
		MuiPickersCalendarHeader: {
			switchHeader: {
				// backgroundColor: lightBlue.A200,
				// color: "white",
			},
		},
		MuiPickersDay: {
			day: {
				color: '#373737',
			},
			daySelected: {
				backgroundColor: '#3D9F0B',
			},
			dayDisabled: {
				color: '#cdcdcd',
			},
			current: {
				color: '#1e1e1e',
			},
		},
		MuiPickersModal: {
			dialogAction: {
				color: '#828282',
			},
		},
	},
});

export default theme;