const APP_CONFIG = {
	COMPANY_NAME: process.env.REACT_APP_COMPANY_NAME,
	API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL,
	API_ENDPOINT_LOGIN: 'api/login/',
	API_ENDPOINT_VALIDATE: 'api/validate/',
	API_ENDPOINT_FORGOT_PASSWORD: 'api/password_reset/',
	API_ENDPOINT_CHANGE_PASSWORD: 'api/change_password/',
	API_ENDPOINT_RESET_PASSWORD_VAL: 'api/password_reset/validate_token/',
	API_ENDPOINT_RESET_PASSWORD: 'api/password_reset/confirm/',
	API_ENDPOINT_OPERATORS: 'api/operators/',
	API_ENDPOINT_FUTURE_PLAYS: 'api/future_plays/',
	API_ENDPOINT_BUY_TICKETS: 'api/buy_card/',
	API_ENDPOINT_PAY_CARD: 'api/pay_card/',
	API_ENDPOINT_BUY_CREDIT: 'api/buy_credit/',
	API_ENDPOINT_RESULT_MATCH: 'api/result_match/',
	API_ENDPOINT_GET_INFO_PERSONAL: 'api/get_person/',
	API_ENDPOINT_STATUS_TRANSACTION: 'api/transaction_status/',
	API_ENDPOINT_REPORT_TRANSACTION: 'api/report_transaction/',
	API_ENDPOINT_PRE_CLOSE_TRANSACTION: 'api/pre_close_transactions/',
	API_ENDPOINT_CLOSE_TRANSACTION: 'api/close_transactions/',
	API_ENDPOINT_VALIDATE_WITHDRAW: 'api/validate_withdraw_credit/',
	API_ENDPOINT_CONFIRM_WITHDRAW: 'api/confirm_withdraw_credit/',
	API_ENDPOINT_GET_PRIZES: 'api/get_prizes/',
	API_ENDPOINT_POINTS_SALE: 'api/points_of_sale/',
	API_USER_COOKIE: 'VibraPOSCookie_userData',
};

export default APP_CONFIG;
