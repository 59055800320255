/* eslint-disable react/destructuring-assignment */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import React, { useState } from 'react';
import { APP_TEXTS } from '../config/app.texts';
import { APP_UTILS } from '../config/app.utils';

const TabletDetails = (props) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const data = props && props.data;
	const tableColumns = data && data.tableColumns;
	const rowData = data && data.rowData;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper
			className="TableWrapper"
			elevation={4}
			style={{ marginBottom: '5%' }}
		>
			<TableContainer className="TableContainer">
				<Table stickyHeader>
					<TableHead className="TableHeader">
						<TableRow>
							{tableColumns.map((column) => (
								<TableCell
									className="TableHeaderCell"
									key={column.id}
									align={column.align}
									style={{
										width: column.width,
										minWidth: column.minWidth,
									}}
								>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
							<TableRow
								key={item.id}
								className={classNames({
									TableRowEven: index % 2,
									TableRowOdd: !(index % 2),
								})}
							>
								{item.pointSale && 
									<TableCell align="center">
										{item.pointSale}
									</TableCell>
								}
								<TableCell align="center">
									{item.date}
								</TableCell>
								<TableCell align="center">
									{item.time}
								</TableCell>
								<TableCell align="center">
									{item.id}
								</TableCell>
								<TableCell align="center">
									{item.type}
								</TableCell>
								<TableCell align="center">
									{item.state}
								</TableCell>
								<TableCell align="center">
									{APP_UTILS.formatCurrency(item.value)}
								</TableCell>
							</TableRow>
						))
						}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				rowsPerPageOptions={[10, 25, 100]}
				count={rowData.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				labelRowsPerPage={APP_TEXTS.TABLE_ROWS_PER_PAGE}
				labelDisplayedRows={({ from, to, count }) => (`${from}-${to} ${APP_TEXTS.TABLE_DISPLAYED_ROWS} ${(count !== -1) ? count : to}`)}
			/>
		</Paper>
	);
}

export default TabletDetails;