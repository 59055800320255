/* eslint-disable no-plusplus */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import classNames from 'classnames';
// Redux and Router
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// Material IU and Icons
import {
	Box,
	Button,
	Card,
	CardContent,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContentText,
	DialogTitle,
	Grid,
	GridListTile,
	GridListTileBar,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	ThemeProvider,
	Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
// React-PDF
import { PDFDownloadLink } from '@react-pdf/renderer';
// Settings, Utils Functions, Language
import APP_CONFIG from '../config/app.config';
import { APP_UTILS } from '../config/app.utils';
import { APP_TEXTS } from '../config/app.texts';
// components
import DocumentPDF from '../Components/ExportToPDF';
// Custom theme
import theme from '../Components/ColorTheme';

const useStyles = {
	styleBigContainer: {
		height: '69%',
		margin: '0',
	},
	styleButtonMessage: {
		display: 'flex',
		flexDirection: 'column',
		textAlign: 'center',
	},
	styleFlexColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	iconTitle: {
		fontSize: '3rem',
	},
	titleInfo: {
		fontWeight: 'bold',
		paddingRight: '5px',
	},
	successDialog: {
		background: 'rgba(168, 177, 43, 1)',
		backgroundImage: 'linear-gradient(90deg, rgba(168, 177, 43, 1) 0%, rgba(23, 152, 0, 1) 100%)',
		padding: '4% ​4% 2% 4%',
		color: '#ffffff',
	},
	cancelDialog: {
		background: 'rgb(201,28,123)',
		backgroundImage: 'linear-gradient(0deg, rgba(201,28,123,1) 0%, rgba(228,58,43,1) 100%)',
		padding: '4% ​4% 2% 4%',
		color: '#ffffff',
	},
	bottomBar: {
		padding: '0',
		display: 'flex',
		justifyContent: 'space-between',
	},
	taxes: {
		padding: '1%',
		margin: '0%',
		borderRadius: '5px',
		background: '#f9e8f2',
	},
	displayFlex: {
		display: 'flex',
	},
	btnDownloadArea: {
		width: '100%',
		margin: '18px 10px',
		textAlign: 'left',
	},
	btnDownload: {
		borderRadius: '150px',
		padding: '5px 15px',
		background: 'linear-gradient(90deg, rgba(212, 212, 212, 1) 0%, rgba(179, 179, 179, 1) 100%)',
		color: '#191919',
		textDecoration: 'none',
	},
};

class CashPrizes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cardsPrize: [],
			document: '',
			idTicket: '',
			messageDialog: '',
			netPrize: 0,
			openDialog: false,
			openDialogConfir: false,
			paymentInProcess: false,
			prize: 0,
			prizePayed: false,
			prizeExpired: false,
			purchasedDate: '',
			styleDialog: useStyles.successDialog,
			taxDetails: {},
			titleDialog: '',
			typeDoc: 'DNI',
			validated: false,
			validationInProcess: false,
			raffleName: "",
			cardIds: "",
			error: {
				type: 'info',
				message: '',
			},
		};

		this.tableColumns = [
			{
				id: 'cardId',
				label: 'Nro. Cartón',
				width: '30%',
				minWidth: '50%',
				align: 'center',
			},
			{
				id: 'prizeId',
				label: 'Premio',
				width: '35%',
				minWidth: '35%',
				align: 'center',
			},
			{
				id: 'amount',
				label: 'Monto',
				width: '35%',
				minWidth: '35%',
				align: 'center',
			},
		];
		this.prizeMap = [];
	}

	componentDidMount() {
		this.userData = this.props.userData;
		this.prizeMap = this.userData.prizesData;
		// this.getPrizes();
	}

	handleValidation = () => {
		this.processValidation();
	};

	setMessage = (type, message) => {
		if (type && message) {
			this.setState({
				error: { type, message },
			});
		}
	};

	handlePayPrize = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (this.state.prizePayed) { return; }
		this.setState({ openDialogConfir: true });
	};

	handleDialogOk = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (this.state.paymentInProcess) { return; }
		this.setState({ paymentInProcess: true });
		this.payCard();
	};

	handleMessageOk = () => {
		this.setState({
			openDialog: false,
			paymentInProcess: false,
		});

		this.goToBack();
	};

	handleDialogCancel = () => {
		this.setState({openDialogConfir: false });
	};

	getNamePrize = (idPrize) => {
		let name = ""
		for (let index = 0; index < this.prizeMap.length; index++) {
			const prize = this.prizeMap[index];
			if (prize.id === idPrize) {
				name = prize.name || (prize.type && prize.type.name) || '';
				break;
			}
		}

		return name;
	};

	resetData = () => {
		this.setState ({
			agencyId: '',
			cardsPrize: [],
			city: '',
			document: '',
			expirationDate: '',
			idTicket: '',
			messageDialog: '',
			netPrize: 0,
			openDialog: false,
			openDialogConfir: false,
			prize: 0,
			prizeExpired: false,
			prizePayed: false,
			raffleDate: '',
			raffleId: '',
			styleDialog: useStyles.successDialog,
			taxDetails: {},
			typeDoc: 'DNI',
			validated: false,
			validationInProcess: false,
			error: {
				type: 'info',
				message: '',
			},
		});
	};

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'idTicket':
				if (e.key === 'Enter' && this.state.idTicket !== '') {
					this.handleValidation();
				}
				break;

			default:
				break;
		}
	};

	inputTextChanged = (e) => {
		if (e.target.value) {
			this.setState({ idTicket: String(e.target.value).toUpperCase() });
		} else {
			this.setState({ idTicket: '' });
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	goToMenu = () => {
		const path = '/';
		this.resetData();
		this.props.history.push(path);
	};

	goToBack = () => {
		this.resetData();
	};

	processResult = (resp) => {
		const state = resp.transaction_state;

		switch (state) {
			case 'TS_SUCCESS':
				this.setState({ 
					messageDialog: APP_TEXTS.SUCCESS_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: useStyles.successDialog,
					openDialog: true,
				});
				break;

			case 'TS_CANCEL':
				this.setMessage('warning', APP_TEXTS.CANCEL_TRANSACTION);
				this.setState({ 
					messageDialog: APP_TEXTS.CANCEL_TRANSACTION,
					titleDialog: APP_TEXTS.CANCEL_TITLE_MESSAGE,
					styleDialog: useStyles.cancelDialog,
					openDialog: true,
				});
				break;

			case 'TS_CLOSE':
				this.setState({ 
					messageDialog: APP_TEXTS.CLOSURE_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: useStyles.successDialog,
					openDialog: true,
				});
				break;

			default:
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				break;
		}
	};

	processResultValidation = (resp) => {
		let purchasedDate = '';
		if (resp.purchased_date) {
			purchasedDate = APP_UTILS.formatDate(resp.purchased_date);
		}

		let raffleDate = '';
		if (resp.match_date) {
			raffleDate = APP_UTILS.formatDate(resp.match_date);
		}

		let expirationDate = '';
		let prizeExpired = false;
		if (resp.expiration_prize_date) {
			expirationDate = APP_UTILS.formatDate(resp.expiration_prize_date);
			prizeExpired = Date.parse(resp.expiration_prize_date) < Date.now();
		}
		

		const taxData = resp.tax_details;
		let taxDetails = [...resp.tax_details];
		if (taxData && taxData.length) {
			taxDetails = taxData.reduce((map, obj) => ({...map, [obj.name]: obj.value}), {});
		}

		const clearError = { type: 'info', message: '' };
		this.setState({
			agencyId: resp.pos_code || '',
			cardsPrize: resp.cards_prize,
			city: resp.pos_city || '',
			document: (resp.person && resp.person.doc) || '',
			email: (resp.person && resp.person.email) || '',
			error: clearError,
			expirationDate,
			netPrize: resp.net_prize,
			prize: resp.total_prize,
			prizeExpired,
			prizePayed: resp.prize_payed || false,
			purchasedDate,
			raffleDate,
			raffleId: resp.match_id,
			raffleName: resp.match_name,
			cardIds: Array.from(new Set(resp.cards_prize.map(item => item.card_id))).join(", "),
			taxDetails,
			typeDoc: (resp.person && resp.person.type_doc) || '',
			validated: true,
			validationInProcess: false,
		});

		if (resp && resp.prize_payed && resp.prize_payed_transaction) {
			const text = APP_TEXTS.PRIZE_PAYED + resp.prize_payed_transaction;
			this.setMessage('warning', text);
		}

		if (prizeExpired && resp.total_prize > 0) {
			this.setMessage('warning', APP_TEXTS.PAY_OUT_OF_DATE);
		}

	};

	processGetPrizes = (response) => {
		const prizeMap = [];
		const data = response;
		// eslint-disable-next-line no-restricted-syntax
		for (const key in data) {
			if (Object.hasOwnProperty.call(data, key)) {
				const prize = {
					id: Number(key),
					type: data[key],
				};
				prizeMap.push(prize);	
			}
		}

		this.prizeMap = prizeMap;
	};

	processErrors = (errorCode) => {
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}

		this.setState({
			validationInProcess: false,
			paymentInProcess: false,
			openDialog: false,
			openDialogConfir: false,
		});
	};

	async processValidation() {
		// check that process is not already running
		if (this.state.validationInProcess) {
			return false;
		}

		if (!this.state.idTicket) {
			this.setMessage('error', APP_TEXTS.REQUIRED_TICKET_NUMBER);
			return false;
		}

		// clear previous error
		this.setState({
			validationInProcess: true,
			error: { type: 'info', message: '' },
		});

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_RESULT_MATCH;

		const params = JSON.stringify({
			"transaction": this.state.idTicket,
			"operator_id": this.userData.operatorId,
		});

		const headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						return response.status;
				}

				return false;
			})
			.then((response) => (response.json) ? response.json() : { "errors": [{code: response}]})
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.cards_prize && resp.person) {
					this.processResultValidation(resp);
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					error: { type: 'warning', message: error },
				});
			});

		return true;
	}

	async payCard() {
		this.setState({
			validationInProcess: true,
			error: { type: 'info', message: '' },
		});

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_PAY_CARD;

		const params = JSON.stringify({
			"transaction": this.state.idTicket,
			"operator_id": this.userData.operatorId,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}

				return false;
			})
			.then((response) => (response.json) ? response.json() : { "errors": [{code: response}]})
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.transaction || resp.transaction_state) {
					this.setState({
						error: { type: 'info', message: '' },
					});

					this.processResult(resp);
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					error: { type: 'info', message: error },
				});
			});

		return true;
	}

	render() {
		const {
			agencyId,
			cardsPrize,
			city,
			document,
			email,
			expirationDate,
			idTicket,
			raffleDate,
			raffleName,
			cardIds,
			raffleId,
			messageDialog,
			netPrize,
			openDialog,
			openDialogConfir,
			prize,
			prizePayed,
			prizeExpired,
			purchasedDate,
			styleDialog,
			taxDetails,
			titleDialog,
			typeDoc,
			validated,
			validationInProcess,
		} = this.state;
		const { tableColumns } = this;
		const dataPDF = {
			agencyId,
			city,
			document,
			expirationDate,
			idTicket,
			netPrize,
			prize,
			raffleDate,
			raffleName,
			raffleId,
			taxDetails,
			typeDoc,
			cardIds
		};
		return (
			<ThemeProvider theme={theme}>
				<Paper className="Main InternalPage CenteredX" variant="outlined">
					<Grid container className="MainGrid InternalPage" elevation={4}>
						<Grid xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{ backgroundImage: "url('./images/pay_card.jpg')" }}
								/>
								<GridListTileBar title={APP_TEXTS.PAY_PRIZE} titlePosition="top" />
							</GridListTile>
						</Grid>
						<Grid xs={12} className="SectionBody h100">
							<Paper className="Body mh100" elevation={0} style={{ marginTop: '1%' }}>
								<Grid container className="BodyTitle">
									{!validated
									&& (
										<Grid
											xs={12}
											sm={5}
											style={{ padding: '2%', margin: '0 auto' }}
										>
											<Card style={useStyles.styleBigContainer} elevation={4}>
												<CardContent style={{ textAlign: 'center' }}>
													<Typography variant="h6" component="h6">
														{APP_TEXTS.CARD_ID_LABEL}
													</Typography>
													<Box style={useStyles.styleFlexColumn}>
														<TextField
															required
															id="idTicket"
															variant="outlined"
															value={idTicket}
															color="primary"
															autoComplete="off"
															onKeyPress={this.onKeyPress}
															onChange={this.inputTextChanged}
															style={{ marginBottom: '5%' }}
														/>
														<Button
															className="Button ButtonHigh greenGradient"
															onClick={this.handleValidation}
														>
															{validationInProcess && <CircularProgress style={{ width: '10%', height: '10%', color: '#fff' }} />}
															{!validationInProcess && APP_TEXTS.BTN_VALIDATE}
														</Button>
													</Box>
												</CardContent>
											</Card>
										</Grid>
									)}
									{validated && (
										// eslint-disable-next-line react/jsx-wrap-multilines
										<>
											<Grid
												xs={12}
												sm={(cardsPrize.length > 0) ? 6 : 12}
												style={{ alignSelf: 'start' }}
											>
												<Card style={useStyles.styleBigContainer} elevation={0}>
													<CardContent style={{ padding: '5px 10px'}}>
														<Typography variant="body1" style={useStyles.displayFlex}>
															<Typography variant="body1" style={useStyles.titleInfo}>
																{typeDoc}:
															</Typography>
															{document}
														</Typography>
														<Typography variant="body1" style={useStyles.displayFlex}>
															<Typography variant="body1" style={useStyles.titleInfo}>
																{APP_TEXTS.MAIL_LABEL}
															</Typography>
															{email}
														</Typography>
														<Typography variant="body1" style={useStyles.displayFlex}>
															<Typography variant="body1" style={useStyles.titleInfo}>
																{APP_TEXTS.CARD_ID_LABEL}
															</Typography>
															{idTicket}
														</Typography>
														<Typography variant="body1" style={useStyles.displayFlex}>
															<Typography variant="body1" style={useStyles.titleInfo}>
																{APP_TEXTS.DATE_PURCHASE_LABEL}
															</Typography>
															{purchasedDate}
														</Typography>
														<Typography variant="body1" style={useStyles.displayFlex}>
															<Typography variant="body1" style={useStyles.titleInfo}>
																{APP_TEXTS.TOTAL_PRIZE_LABEL}
															</Typography>
															{APP_UTILS.formatCurrency(prize)}
														</Typography>
														<Typography variant="body1" style={useStyles.displayFlex}>
															<Typography variant="body1" style={useStyles.titleInfo}>
																{APP_TEXTS.TOTAL_PAY_LABEL}
															</Typography>
															{APP_UTILS.formatCurrency(netPrize)}
														</Typography>
														{(Object.keys(taxDetails).length > 0) && (
															<CardContent style={useStyles.taxes}>
																{Object.entries(taxDetails).map(([key, value]) => (
																	<Typography variant="body1" style={useStyles.displayFlex}>
																		<Typography variant="body1" style={useStyles.titleInfo}>
																			{key}
																		</Typography>
																		{APP_UTILS.formatCurrency(value)}
																	</Typography>
																))
																}
															</CardContent>
														)}
													</CardContent>
												</Card>
											</Grid>
											{(cardsPrize.length > 0)
											&& (
												<Grid
													xs={12}
													sm={(cardsPrize.length > 0) ? 6 : 12}
													style={{ alignSelf: 'start' }}
												>
													<div style={useStyles.btnDownloadArea}>
														<PDFDownloadLink
															document={<DocumentPDF data={dataPDF} />}
															fileName={`${idTicket}.pdf`}
															style={useStyles.btnDownload}
														>
															{APP_TEXTS.BTN_DOWNLOAD_WITHHOLDINGS}
														</PDFDownloadLink>
													</div>
													<Paper className="TableWrapper">
														<TableContainer className="TableContainer" elevation={4}>
															<Table stickyHeader aria-label="sticky table dense">
																<TableHead className="TableHeader">
																	<TableRow>
																		{(cardsPrize.length > 0) && tableColumns.map((column) => (
																			<TableCell
																				className="TableHeaderCell"
																				key={column.id}
																				align={column.align}
																				style={{ width: column.width, minWidth: column.minWidth }}
																			>
																				{column.label}
																			</TableCell>
																		))}
																	</TableRow>
																</TableHead>
																<TableBody>
																	{cardsPrize.map((item, index) => (
																		<TableRow
																			key={item.id}
																			className={classNames({
																				TableRowEven: index % 2,
																				TableRowOdd: !(index % 2),
																			})}
																		>
																			<TableCell align="center">
																				{item.card_id}
																			</TableCell>
																			<TableCell align="center">
																				{this.getNamePrize(item.prize_id)}
																			</TableCell>
																			<TableCell align="center">
																				{APP_UTILS.formatCurrency(
																					item.value
																				)}
																			</TableCell>
																		</TableRow>
																	))}
																</TableBody>
															</Table>
														</TableContainer>
													</Paper>
												</Grid>
											)}
											<Grid
												xs={12}
												style={{ marginTop: (Object.keys(taxDetails).length > 0) ? '0%' : '8%' }}
											>
												<Card elevation={0}>
													<CardContent style={useStyles.styleButtonMessage}>
														<Button
															id="buttonPay"
															className={classNames({
																Button,
																greenGradient: prize > 0,
																greyGradient: (prize === 0 || prizePayed || prizeExpired),
															})}
															disabled={prize === 0 || prizePayed || prizeExpired}
															style={{ borderRadius: '2em', padding: '1.5% 0px' }}
															onClick={this.handlePayPrize}
														>
															{(prize > 0) ? `${APP_TEXTS.PAY_TICKET}  (${APP_UTILS.formatCurrency(netPrize)})` : APP_TEXTS.PAY_TICKET}
														</Button>
													</CardContent>
												</Card>
											</Grid>
										</>
									)}
								</Grid>
							</Paper>
						</Grid>
						<Grid xs={12} sm={12} className="SectionActions">
							<Paper className="Actions h100" elevation={0} style={useStyles.bottomBar}>
								<Collapse
									in={this.state.error.message !== ''}
									style={{ width: '100%'}}
								>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
										style={{ padding: '0px 16px', margin: 'auto' }}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								{validated && (
									<Button className="Button redGradient" onClick={this.goToBack}>
										{APP_TEXTS.BACK}
									</Button>
								)}
								<Button className="Button redGradient" onClick={this.goToMenu}>
									{APP_TEXTS.GO_TO_MENU}
								</Button>
							</Paper>
						</Grid>
					</Grid>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialogConfir}
					>
						<LinearProgress className="linear-progress-warning" />
						<DialogTitle style={{ backgroundColor: '#221f1f', color: '#ffffff', padding: '8%' }}>
							<b>{APP_TEXTS.TITLE_MESSAGE_CONFIRMATION}</b>
						</DialogTitle>
						<DialogContentText style={{ padding: '5%' }}>
							<Typography variant="h6" gutterBottom style={{ color: '#000000' }}>
								{APP_TEXTS.CONFIRM_MESSAGE_PRIZE}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button
								className="Button redGradient"
								autoFocus
								onClick={this.handleDialogCancel}
								color="default"
							>
								{APP_TEXTS.CANCEL}
							</Button>
							<Button
								className="Button greenGradient"
								onClick={this.handleDialogOk}
								color="default"
							>
								{APP_TEXTS.BTN_PAY}
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialog}
					>
						<DialogTitle style={styleDialog}>
							<Grid style={{ display: 'flex', margin: '0%'}}>
								<Grid xs={2}>
									{(titleDialog === APP_TEXTS.SUCCESS_TITLE_MESSAGE) 
										? <CheckCircleOutlineIcon style={useStyles.iconTitle} />
										: <BlockIcon style={useStyles.iconTitle} />
									}
								</Grid>
								<Grid xs={10}>
									<Typography
										variant="h6"
										style={{ paddingTop: '2%' }}
									>
										{titleDialog}
									</Typography>
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContentText style={{ padding: '6%', margin: '0% 0% 6% 0%' }}>
							<Typography variant="h6" gutterBottom style={{ color: '#000000' }}>
								{messageDialog}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button
								className="Button greenGradient"
								onClick={this.handleMessageOk}
								color="default"
							>
								{APP_TEXTS.ACCEPT}
							</Button>
						</DialogActions>
					</Dialog>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

export default withRouter(connect(mapStateToProps)(CashPrizes));
