/* eslint-disable react/destructuring-assignment */
import React from 'react';
import classNames from 'classnames';
// Redux and Router
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// Material IU and Icons
import {
	Button,
	Paper,
	Grid,
	GridListTile,
	GridListTileBar,
	Box,
	Card,
	CardContent,
	Typography,
	Fab,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Collapse,
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogActions,
	CircularProgress,
	ThemeProvider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
// Configuration, Utils and Language
import APP_CONFIG from '../config/app.config';
import { APP_UTILS } from '../config/app.utils';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';

const styleBigContainer = {
	display: 'flex',
	margin: '1% 1%',
};

const styleControlButtons = {
	margin: '5%',
	backgroundColor: '#7F7F7F',
	borderRadius: '1em !important',
};

const styleDisplayCartones = {
	display: 'flex',
	flexDirection: 'column',
	textAlign: 'left',
	margin: 'auto',
};

// const styleTotalColumn = {
// 	boxShadow:
// 		'0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
// };

const styleSelect = {
	minWidth: '20%',
	maxWidth: '20%',
	marginRight: '1%',
};

const bottomBar = {
	padding: '0',
	display: 'flex',
	justifyContent: 'space-between',
};

const successDialog = {
	background: 'rgba(168, 177, 43, 1)',
	backgroundImage: 'linear-gradient(90deg, rgba(168, 177, 43, 1) 0%, rgba(23, 152, 0, 1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const cancelDialog = {
	background: 'rgb(201,28,123)',
	backgroundImage: 'linear-gradient(0deg, rgba(201,28,123,1) 0%, rgba(228,58,43,1) 100%)',
	padding: '4% ​4% 2% 4%',
	color: '#ffffff',
};

const iconTitle = {
	fontSize: '3rem',
};

const mediumText = {
	fontSize: '3vh',
};

class BuyTicketsPayment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			amount: 0,
			buyInProcess: false,
			currency: 'ARS',
			doc: '',
			email: '',
			messageDialog: '',
			openDialog: false,
			raffleData: '',
			styleDialog: successDialog,
			textMainButton: 'Confirmar Compra',
			titleDialog: '',
			total: 0,
			typeDoc: 'DNI',
			userId: '',
			validatedMail: false,
			validationInProcess: false,
			error: {
				type: 'info',
				message: '',
			},
		};

		this.addCarton = this.addCarton.bind(this);
		this.deleteCarton = this.deleteCarton.bind(this);
		this.changeAmount = this.changeAmount.bind(this);
		this.validateUser = this.validateUser.bind(this);
		this.buyCard = this.buyCard.bind(this);
	}

	componentDidMount() {
		const data = this.props.raffleData.selected;
		if (data) {
			this.setState({ raffleData: data });
		}
	}

	handleDocument = (e) => {
		this.clearError();
		const newDoc = e.target.value;
		if (Number(newDoc) >= 0) {
			if (newDoc) {
				this.setState({ doc: newDoc });
			} else {
				this.setState({ doc: '' });
			}
		} else {
			this.setMessage('error', APP_TEXTS.REQUIRED_INVALID_DOC);
		}
	};

	handleChangeSelect = (e) => {
		if (e.target.value) {
			this.setState({ typeDoc: e.target.value });
		}
	};

	changeEmail = (e) => {
		if (e.target.value) {
			this.setState({ email: e.target.value });
		} else {
			this.setState({ email: '' });
		}

		this.validateMail();
	};

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'doc':
				if (e.key === 'Enter' && this.state.doc !== '') {
					this.validateUser();
				}
				break;

			default:
				break;
		}
	};

	validateMail = () => {
		const validation = (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)));
		this.state.validatedMail = validation;

		return validation;
	};

	goToHome = () => this.props.history.goBack();

	processErrors = (errorCode) => {
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}
	};

	processResult = (resp) => {
		const state = resp.transaction_state;

		switch (state) {
			case 'TS_SUCCESS':
				this.setState({ 
					messageDialog: APP_TEXTS.SUCCESS_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: successDialog,
					openDialog: true,
					buyInProcess: false,
				});
				break;

			case 'TS_CANCEL':
				this.setMessage('warning', APP_TEXTS.CANCEL_TRANSACTION);
				this.setState({ 
					messageDialog: APP_TEXTS.CANCEL_TRANSACTION,
					titleDialog: APP_TEXTS.CANCEL_TITLE_MESSAGE,
					styleDialog: cancelDialog,
					openDialog: true,
					buyInProcess: false,
				});
				break;

			case 'TS_CLOSE':
				this.setState({ 
					messageDialog: APP_TEXTS.CLOSURE_TRANSACTION,
					titleDialog: APP_TEXTS.SUCCESS_TITLE_MESSAGE,
					styleDialog: successDialog,
					openDialog: true,
					buyInProcess: false,
				});
				break;

			default:
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				break;
		}
	};

	setMessage = (type, message) => {
		if (type && message) {
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	handleDialogOk = () => {
		this.setState({ openDialog: false });
		this.goToHome();
	};

	async validateUser() {
		this.clearError();
		
		if (this.state.validationInProcess) {
			return;
		}

		if (!this.state.doc) {
			this.setMessage('error', APP_TEXTS.REQUIRED_DOC);
			return;
		}

		this.setState({
			validationInProcess: true,
			email: '',
		});

		const { token } = this.props.userData;
		const param = JSON.stringify({
			"type_doc": this.state.typeDoc,
			"doc": this.state.doc,
		});

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_GET_INFO_PERSONAL;

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: param,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;
				
					case 124:
					case 401:
						// Unauthorized access
						this.goToHome();
						break;
				
					default:
						// Another status, send unexpected error
						this.processErrors(0);
						break;
				}
			
				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					this.setState({ validationInProcess: false });
					return;
				}

				if (resp.email) {
					this.setState({ 
						email: resp.email,
						userId: resp.casino_user_id,
						validationInProcess: false,
					});
				}

			})
			.catch((error) => {
				console.log(`Error al validar el cliente: ${error}`);
			});
	}

	async buyCard() {
		if (this.state.buyInProcess) {
			return;
		}

		if (!this.state.doc) {
			this.setMessage('error', APP_TEXTS.REQUIRED_DOC);
			return;
		}

		if (this.state.email) {
			this.validateMail();
			if (this.state.validatedMail) {
				this.setMessage('warning', APP_TEXTS.REQUIRED_INVALID_EMAIL);
				return;
			}

			this.clearError();
			this.setState({
				buyInProcess: true,
			});

			const { token, operatorId } = this.props.userData;
			const doc = Number(this.state.doc);
			const casinoUserId = this.state.userId || this.state.email;
			const dataToBuy = JSON.stringify({
				"type_doc": this.state.typeDoc,
				"doc": doc,
				"email": this.state.email,
				"casino_user_id": casinoUserId,
				"match_id": this.state.raffleData.id,
				"quantity": this.state.amount,
				"operator_id": operatorId,
				"amount": this.state.total,
				"currency": this.state.currency,
			});

			let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
			requestUrl += APP_CONFIG.API_ENDPOINT_BUY_TICKETS;

			let headers = {};
			headers = new Headers({
				'Content-Type': 'application/json',
				'Authorization': `JWT ${token}`,
			});

			await fetch(requestUrl, {
				method: 'POST',
				body: dataToBuy,
				headers,
			})
				.then((response) => {
					switch (response.status) {
						case 200:
						case 400:
							// VALID RESPONSE
							return response;
					
						case 401:
							// Unauthorized access
							this.props.history.push('/');
							break;
					
						default:
							// Another status, send unexpected error
							this.processErrors(0);
							break;
					}
				
					return false;
				})
				.then((response) => (response.json) ? response.json() : { "errors": [{code: response}]})
				.then((resp) => {
					// Check status for errors
					if (resp.errors && resp.errors.length) {				
						// Get Error code
						const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
						this.processErrors(errorCode);
						this.setState({ buyInProcess: false });
						return;
					}
					
					if (resp.transaction_number || resp.transaction_state) {
						this.processResult(resp);
					}
				})
				.catch((error) => {
					console.log(`Error al realizar la compra del carton: ${error}`);
				});
		} else {
			this.setMessage('warning', APP_TEXTS.REQUIRED_EMAIL);
		}
	}

	addCarton() {
		const { state } = this;
		const newAmount = state.amount + 1;
		this.setState({ amount: newAmount });

		this.calculateTotal(newAmount);
	}

	deleteCarton() {
		const { state } = this;
		let newAmount = state.amount - 1;
		newAmount = newAmount < 0 ? 0 : newAmount;
		this.setState({ amount: newAmount });

		this.calculateTotal(newAmount);
	}

	changeAmount(e) {
		let newAmount = false;
		if (e.key && ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105))) {
			newAmount = String(this.state.amount);
			newAmount = newAmount.concat(e.key);
			this.setState({ amount: Number(newAmount) });
		} else if (e.keyCode === 8 && this.state.amount > 0) {
			newAmount = String(this.state.amount);
			newAmount = newAmount.slice(0, -1);
			this.setState({ amount: Number(newAmount) });
		}

		if (newAmount && newAmount >= 0) {
			this.calculateTotal(newAmount);
		}
	}

	calculateTotal(amount) {
		const price = this.state.raffleData.value;
		const newTotal = price * amount;

		this.setState({ total: newTotal });
	}

	render() {
		const {
			amount, total, doc, email, typeDoc, openDialog, messageDialog, styleDialog, validationInProcess, buyInProcess, titleDialog, textMainButton,
		} = this.state;
		const { name, date, time, value } = this.state.raffleData;
		return (
			<ThemeProvider theme={theme}>
				<Paper className="Main InternalPage CenteredX" variant="outlined" elevation={12}>
					<Grid container className="MainGrid InternalPage">
						<Grid item xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{ backgroundImage: "url('../images/buy_card.jpg')" }}
								/>
								<GridListTileBar
									title={APP_TEXTS.BUY_CARD}
									titlePosition="top"
								/>
							</GridListTile>
						</Grid>
						<Grid
							item
							xs={12}
							className="SectionBody"
							style={{ marginBottom: '4%' }}
						>
							<Paper className="Body mh100" elevation={0}>
								<Grid
									container
									xs={12}
									direction="row"
									justify="flex-start"
									alignItems="flex-start"
									style={{ marginTop: '1%' }}
								>
									<Grid
										item
										xs={12}
										md={8}
										direction="column"
										justify="flex-start"
										alignItems="flex-start"
									>
										<Card style={styleBigContainer} elevation={0}>
											<CardContent>
												<Typography variant="h6" style={{ display: 'flex' }}>
													<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
														{APP_TEXTS.DATE_RAFFLE_LABEL}
													</Typography>
													{date}
												</Typography>
												<Typography variant="h6" style={{ display: 'flex' }}>
													<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
														{APP_TEXTS.HOUR_RAFFLE_LABEL}
													</Typography>
													{time}
												</Typography>
												<Typography variant="h6" style={{ display: 'flex' }}>
													<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
														{APP_TEXTS.CARD_VALUE_LABEL}
													</Typography>
													{APP_UTILS.formatCurrency(value)}
												</Typography>
												<Typography variant="h6" style={{ display: 'flex' }}>
													<Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '5px' }}>
														{APP_TEXTS.RAFFLE_NAME_LABEL}
													</Typography>
													{name}
												</Typography>
											</CardContent>
										</Card>
										<Card style={styleBigContainer} elevation={2}>
											<CardContent className="w100">
												<Typography
													variant="h6"
													style={{ paddingBottom: '1.1%' }}
												>
													{APP_TEXTS.PLAYER_PERSONAL_DATA}
												</Typography>
												<Box>
													<FormControl variant="outlined" style={styleSelect}>
														<InputLabel id="labelDocType">{APP_TEXTS.TYPE_LABEL}</InputLabel>
														<Select
															labelId="labelSelectDocType"
															id="selectDocType"
															value={typeDoc}
															onChange={this.handleChangeSelect}
															label="Tipo Doc."
														>
															<MenuItem value="">
																<em>--</em>
															</MenuItem>
															<MenuItem value="DNI">{APP_TEXTS.DNI_LABEL}</MenuItem>
															<MenuItem value="PASS">
																{APP_TEXTS.PASS_LABEL}
															</MenuItem>
															<MenuItem value="LC">
																{APP_TEXTS.LC_LABEL}
															</MenuItem>
															<MenuItem value="LE">
																{APP_TEXTS.LE_LABEL}
															</MenuItem>
														</Select>
													</FormControl>
													<TextField
														id="doc"
														label="Nro. Documento"
														variant="outlined"
														value={doc}
														required
														autoComplete="off"
														onKeyPress={this.onKeyPress}
														onChange={this.handleDocument}
														style={{ width: '30%', marginRight: '1%' }}
													/>
													<Box style={{ display: 'inline-block', marginTop: '1%' }}>
														<Button
															className="Button ButtonHigh greenGradient"
															onClick={this.validateUser}
															disabled={this.validatedMail}
														>
															{validationInProcess && <CircularProgress style={{ width: '20%', height: '20%', color: '#fff' }} />}
															{!validationInProcess && APP_TEXTS.BTN_SEARCH}
														</Button>
													</Box>
												</Box>
												<Box xs={4} style={{ marginTop: '1.5%' }}>
													<TextField
														id="email"
														label="Correo electrónico"
														variant="outlined"
														value={email}
														autoComplete="off"
														onChange={this.changeEmail}
														fullWidth
													/>
												</Box>
											</CardContent>
										</Card>
									</Grid>
									<Grid
										item
										xs={12}
										md={4}
										direction="column"
										justify="flex-start"
										alignItems="flex-start"
										alignSelf="flex-start"
									>
										<Card style={styleBigContainer} elevation={2}>
											<CardContent style={styleDisplayCartones}>
												<Typography variant="h5" style={{ fontWeight: 'bold' }}>
													Cartones
												</Typography>
												<Typography variant="h1">
													{amount}
												</Typography>
											</CardContent>
											<Box
												textAlign="center"
												xs={12}
												style={{
													margin: 'auto',
													paddingTop: '5%',
													width: '40%',
												}}
											>
												<Box
													display="flex"
													style={{marginBottom: '2%'}}
												>
													<TextField
														id="quantity"
														label="Cartones"
														variant="outlined"
														autoComplete="off"
														value={(Number(amount) === 0) ? ' ' : Number(amount)}
														style={mediumText}
														onKeyDown={this.changeAmount}
														fullWidth
													/>
												</Box>
												<Box
													display="flex"
													justifyContent="center"
												>
													<Fab
														className="greenGradient"
														style={styleControlButtons}
														onClick={this.addCarton}
														size="medium"
													>
														<AddIcon />
													</Fab>
													<Fab
														className="greenGradient"
														style={styleControlButtons}
														onClick={this.deleteCarton}
														size="medium"
													>
														<RemoveIcon />
													</Fab>
												</Box>
											</Box>
										</Card>
										<Card elevation={2}>
											<CardContent
												style={{
													display: 'flex',
													flexDirection: 'column',
													textAlign: 'center',
												}}
											>
												<Typography variant="h5" style={{ fontWeight: 'bold' }}>
													Total
												</Typography>
												<Typography variant="h2" component="h2">
													{APP_UTILS.formatCurrency(total)}
												</Typography>
												<Button
													className={classNames({
														Button,
														greenGradient: value > 0 && amount > 0,
														greyGradient:
															value === undefined || amount === 0,
													})}
													disabled={value === undefined || amount === 0}
													style={{ padding: '1.2em 0', marginRight: '1em' }}
													onClick={this.buyCard}
												>
													{buyInProcess && <CircularProgress style={{ width: '10%', height: '10%', color: '#fff' }} />}
													{!buyInProcess && textMainButton}
												</Button>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} className="SectionActions">
							<Paper className="Actions h100" elevation={0} style={bottomBar}>
								<Collapse
									in={this.state.error.message !== ''}
									style={{ width: '100%'}}
								>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
										style={{ padding: '0px 16px', margin: 'auto' }}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								<Button
									color="primary"
									className="Button ButtonHigh redGradient"
									onClick={this.goToHome}
								>
									{APP_TEXTS.BACK}
								</Button>
							</Paper>
						</Grid>
					</Grid>
					<Dialog
						disableBackdropClick
						disableEscapeKeyDown
						maxWidth="xs"
						fullWidth
						aria-labelledby="confirmation-dialog-title"
						open={openDialog}
					>
						<DialogTitle style={styleDialog}>
							<Grid style={{ display: 'flex', margin: '0%'}}>
								<Grid xs={2}>
									{(titleDialog === APP_TEXTS.SUCCESS_TITLE_MESSAGE) 
										? <CheckCircleOutlineIcon style={iconTitle} />
										: <BlockIcon style={iconTitle} />
									}
								</Grid>
								<Grid xs={10}>
									<Typography
										variant="h6"
										style={{ paddingTop: '2%' }}
									>
										{titleDialog}
									</Typography>
								</Grid>
							</Grid>
						</DialogTitle>
						<DialogContentText style={{ padding: '6%', margin: '0% 0% 6% 0%' }}>
							<Typography variant="h6" component="h6" gutterBottom style={{ color: '#000000' }}>
								{messageDialog}
							</Typography>
						</DialogContentText>
						<DialogActions>
							<Button
								className="Button greenGradient"
								onClick={this.handleDialogOk}
								color="default"
							>
								{APP_TEXTS.ACCEPT}
							</Button>
						</DialogActions>
					</Dialog>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
	raffleData: state.raffleData,
});

export default withRouter(connect(mapStateToProps)(BuyTicketsPayment));
