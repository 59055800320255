/* eslint-disable react/destructuring-assignment */
// React
import React from 'react';
// React - PDF
import {
	Document,
	Page,
	StyleSheet,
	Text,
	View,
} from '@react-pdf/renderer';
// Utils
import { APP_UTILS } from '../config/app.utils';

const DocumentPDF = (props) => {
	const { data } = props;
	const {
		agencyId,
		document,
		city,
		expirationDate,
		idTicket,
		netPrize,
		prize,
		raffleDate,
		raffleId,
		raffleName,
		cardIds,
		taxDetails,
		typeDoc,
	} = data;
	// Create styles
	const styles = StyleSheet.create({
	  page: {
	    fontSize: 11,
			paddingTop: 30,
			paddingLeft: 60,
			paddingRight: 60,
			lineHeight: 1.5,
			flexDirection: 'column',
	    backgroundColor: '#ffffff',
	  },
		mainContainer: {
			border: '2px solid #000000',
			padding: '2% 2% 1% 2%',
		},
		subtitle: {
			fontSize: 16,
			fontWeight: 'extrabold',
		},
		section: {
			width: '100%',
			flexDirection: 'column',
			alignContent: 'center',
			margin: '1.2% 0',
		},
		row: {
			alignContent: 'center',
			flexDirection: 'row',
			width: '100%',
		},
		column: {
			flexDirection: 'column',
			width: '48%',
		},
	  cell: {
			borderBottom: '1px solid #000000',
			padding: '4px 0',
			width: '100%',
			fontSize: 13,
	  },
		signatureLine: {
			alignContent: 'center',
			borderTop: '1px dashed #000000',
			paddingTop: '4%',
			textAlign: 'center',
		},
		signatureArea: {
			width: '28%',
			margin: '5% 2% 0 2%',
			alignContent: 'space-between',
			textAlign: 'center',
		},
		division: {
			width: '100%',
			paddingBottom: '2px',
			marginBottom: '6px',
			borderBottom: '2px solid #000000',
		},
		signatureCenter: {
			width: '50%',
			margin: 'auto',
			marginTop: '5%',
			alignContent: 'center',
			textAlign: 'center',
		},
		fontSize: {
			fontSize: 13,
		},
	});

	return (
		<Document>
			<Page size="A4" orientation="portrait" style={styles.page}>
				<View style={styles.mainContainer}>
					<View style={[styles.section, styles.subtitle]}>
						<Text>Liquidación por la retención del impuesto de emergencia a los premios ganados en juegos de sorteos Leyes 20.630 23.351-24.800, para Bingo del Litoral.</Text>
						<br />
					</View>
					<View style={styles.section}>
						<View style={styles.row}>
							<View style={styles.column}>
								<Text style={styles.cell}>Provincia</Text>
								<Text style={styles.cell}>Numero de Concurso</Text>
								<Text style={styles.cell}>Fecha del Sorteo</Text>
								<Text style={styles.cell}>Fecha de caducidad</Text>
								<Text style={styles.cell}>Número de Cupón</Text>
								<Text style={styles.cell}>Jugada en Agencia Autorizada Nº</Text>
								<Text style={styles.cell}>Premio Correspondiente</Text>
								{Object.entries(taxDetails).map(([key]) => (
									<Text key={key} style={styles.cell}>{key}</Text>
								))}
								<Text style={styles.cell}>Importe Neto del Premio</Text>
							</View>
							<View style={styles.column}>
								<Text style={styles.cell}>{city || ' '}</Text>
								<Text style={styles.cell}>{raffleName || ' '} ({raffleId || ' '})</Text>
								<Text style={styles.cell}>{raffleDate || ' '}</Text>
								<Text style={styles.cell}>{expirationDate || ' '}</Text>
								<Text style={styles.cell}>{cardIds || ' '} ({idTicket || ' '})</Text>
								<Text style={styles.cell}>{agencyId || ' '}</Text>
								<Text style={styles.cell}>{APP_UTILS.formatCurrency(prize)}</Text>
								{Object.entries(taxDetails).map(([key, value]) => (
									<Text key={key} style={styles.cell}>
										{APP_UTILS.formatCurrency(value)}
									</Text>
								))}
								<Text style={styles.cell}>{APP_UTILS.formatCurrency(netPrize)}</Text>
							</View>
						</View>
					</View>
					<View style={styles.section}>
						<Text style={styles.fontSize}>Certificamos que la retención de impuestos detallados en la presente liquidación ha sido practicada por I.A.F.A.S. quien la ingresará a la A.F.I.P. en tiempo y forma</Text>
					</View>
					<View style={styles.section}>
						<Text style={[styles.signatureCenter, styles.signatureLine, styles.fontSize]}>Sello y Firma</Text>
					</View>
					<View style={styles.section}>
						<Text style={[styles.division, styles.subtitle]}>Datos del Beneficiario</Text>
						<Text style={styles.fontSize}>Apellido y Nombre:</Text>
						<Text style={styles.fontSize}>Domicilio:</Text>
						<Text style={styles.fontSize}>Localidad:</Text>
						<Text style={styles.fontSize}>{`${typeDoc}: ${document}`}</Text>
						<Text style={styles.fontSize}>CUIT/CUIL:</Text>
					</View>
				</View>
				<View style={styles.section}>
					<View style={styles.row}>
						<Text style={[styles.signatureArea, styles.signatureLine]}>LUGAR Y FECHA</Text>
						<Text style={[styles.signatureArea, styles.signatureLine]}>FIRMA DEL BENEFICIARIO</Text>
						<Text style={[styles.signatureArea, styles.signatureLine]}>INTER. ORG. PAGADOR</Text>
					</View>
				</View>
			</Page>
		</Document>
	)}

export default DocumentPDF;
