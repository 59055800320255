/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-constructor */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import es from 'date-fns/locale/es';
import {
	Box,
	Button,
	Collapse,
	Dialog,
	DialogActions,
	DialogContentText,
	DialogTitle,
	FormControl,
	Grid,
	GridListTile,
	GridListTileBar,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	ThemeProvider,
	Toolbar,
	Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import classNames from 'classnames';
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';
import TableTotals from '../Components/tableTotals';
import TabletDetails from '../Components/tableDetails';
import theme from '../Components/ColorTheme';
import ExportToCSV from '../Components/ExportToCsv';
import { APP_UTILS } from '../config/app.utils';

const bottomBar = {
	padding: '0',
	display: 'flex',
	justifyContent: 'space-between',
};

const toolbarBasicBtn = {
	borderRadius: '150px',
	margin: '0px 10px 0px 0px',
};

const toolbarBtnSelected = {
	borderRadius: '150px',
	margin: '0px 10px 0px 0px',
	backgroundColor: '#C91C7C',
	color: '#ffffff',
};

// Definition
class CloseTransactions extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toDate: new Date(),
			serverData: null,
			totalsData: [],
			detailsData: [],
			balanceData: '',
			arrayTransactions: [],
			posId: 0,
			pointsSale: [],
			disableCloseButton: true,
			showTotals: false,
			showDetails: false,
			showBalance: false,
			openDialogConfir: false,
			error: {
				type: 'info',
				message: '',
			},
		};

		// Get User Data
		this.userData = {};

		this.itemsPerPage = 10;
		this.dateToday = new Date();
		this.selectedDateMin = this.dateToday;
		this.selectedDateMin.setDate(this.dateToday.getDate() - 7);
		this.selectedDateMax = new Date();

		this.tableTotalsColumns = [
			{
				id: 'operation',
				label: 'Tipo de Operación',
				width: '25%',
				minWidth: '25%',
				align: 'center',
			},
			{
				id: 'Balance',
				label: 'Total',
				width: '25%',
				minWidth: '25%',
				align: 'center',
			},
		];

		this.tableBalanceColumns = [
			{
				id: 'balance',
				label: 'Balance',
				width: '50%',
				minWidth: '50%',
				align: 'center',
			},
		];

		this.tableDetailsColumns = [
			{
				id: 'pointSale',
				label: 'Punto de Venta',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
			{
				id: 'date',
				label: 'Fecha',
				width: '12%',
				minWidth: '12%',
				align: 'center',
			},
			{
				id: 'hora',
				label: 'Hora',
				width: '10%',
				minWidth: '10%',
				align: 'center',
			},
			{
				id: 'transactionNumber',
				label: 'Número de Operación',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
			{
				id: 'typeTransaction',
				label: 'Tipo de Transacción',
				width: '18%',
				minWidth: '18%',
				align: 'center',
			},
			{
				id: 'stateTransaction',
				label: 'Estado',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
			{
				id: 'value',
				label: 'Valor',
				width: '15%',
				minWidth: '15%',
				align: 'center',
			},
		];

		this.transactions_type = [
			['BUY_CARD', 'PAY_PRIZE', 'BUY_CREDIT', 'CREDIT_WITHDRAWAL','COMMISSION'],
		];

		this.transactions_state = [
			['TS_SUCCESS'],
		];

		this.getTransactions = this.getTransactions.bind(this);
		this.closeTransactionsRequest = this.closeTransactionsRequest.bind(this);
	}

	componentDidMount() {
		this.getPointsSale();			
	}

	async getTransactions() {
		this.setMessage('Info', APP_TEXTS.SEARCHING);
		this.setState({ 
			detailsData: [],
			totalsData: [],
			balanceData: '',
		});
		// Get User Data from store
		let posIds = [];
		if (this.state.posId === 'all') {
			const pointsSale = [...this.state.pointsSale];
			const allPointsSalesIds = pointsSale.map((pos) => pos.id);
			posIds = [...allPointsSalesIds];
		} else {
			posIds.push(this.state.posId);
		}
		
		this.userData = this.props.userData;
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_PRE_CLOSE_TRANSACTION;
		const params = JSON.stringify({
			"pos_id": posIds,
			"date_to": this.state.toDate,
			"operator_id": this.userData.operatorId,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						// this.setErrorMessageOnForm('error', APP_TEXTS.CONNECTION_ERROR);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.transactions && resp.totals) {
					this.clearError();
					this.setState({
						serverData: resp,
					});

					this.processResult();	

				} else if(resp.message) {
					this.setMessage('Info', resp.message);
				} else if(resp.transactions && resp.transactions.length === 0) {
					this.setMessage('', APP_TEXTS.NOT_REPORT_FOUND);
					this.setState({ 
						serverData: null,
						detailsData: [],
						totalsData: [],
					 });
				}
			})
			.catch((error) => {
				console.log(error);
				// this.setState({
				// reportMessage: `Error: ${error}`,
				// });
			});
	}

	getPointsSale = () => {
		const { pointsSale } = this.props.userData;
		const posId = (pointsSale && pointsSale.length === 1) ? pointsSale[0].id : 0;

		this.setState({ 
			pointsSale,
			posId,
		});
	};

	processErrors = (errorCode) => {
		// Check for errors
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}
	};

	processResult = () => {
		const { serverData } = this.state;
		const parsedData = [];
		const totals = [];
		const arrayTrans = [];
		let sortedData = [];
		const transactions = serverData.transactions;

		if (serverData.totals) {
			Object.entries(serverData.totals).forEach(([key, value]) => {
				const elem = {
					operation: this.parseTextFromApi(key),
					total: value,
				};

				totals.push(elem);
			});
		}

		for (let index = 0; index < transactions.length; index++) {
			const trans = transactions[index];
			const created = Date.parse(trans.created);

			const year = new Date(created).getFullYear();
			let month = new Date(created).getMonth() + 1;
			let day = new Date(created).getDate();
			month = ( month >= 10) ? month : `0${month}`;
			day = ( day >= 10) ? day : `0${day}`;
			const date = `${year}-${month}-${day}`;

			let h = new Date(created).getHours();
			let m = new Date(created).getMinutes();
			let s = new Date(created).getSeconds();
			h = ( h >= 10) ? h : `0${h}`;
			m = ( m >= 10) ? m : `0${m}`;
			s = ( s >= 10) ? s : `0${s}`;
			const time = `${h}:${m}:${s}`;

			const pointsSale = [...this.state.pointsSale];
			let pointSale = pointsSale.filter((pos) => pos.id === trans.pos_id);
			pointSale = pointSale[0].name;

			const data = {
				pointSale,
				date,
				time,
				id: trans.transaction_number,
				type: this.parseTextFromApi(trans.transaction_type),
				state: this.parseTextFromApi(trans.transaction_state),
				value: trans.amount,
			};

			parsedData.push( data );
			arrayTrans.push( trans.transaction_number );
		}

		if (parsedData.length) {
			sortedData = parsedData.sort((a, b) => {
				const aDate = new Date(a.date).getTime();
				const bDate = new Date(b.date).getTime();
				return aDate - bDate;
			});
		}

		this.setState({
			detailsData: sortedData,
			totalsData: totals,
			arrayTransactions: arrayTrans,
			disableCloseButton: (!sortedData.length),
		});

		if (!sortedData.length) {
			this.setMessage('info', APP_TEXTS.NO_TRANSACTIONS_CLOSE);
		}

		this.setShowTotals();
	};

	parseTextFromApi = (text) => {
		let newText = '';

		switch (text) {
			case 'BUY_CARD':
				newText = APP_TEXTS.BUY_CARD;
				break;

			case 'PAY_PRIZE':
				newText = APP_TEXTS.PAY_PRIZE;
				break;

			case 'BUY_CREDIT':
				newText = APP_TEXTS.BUY_CREDIT;
				break;

			case 'CREDIT_WITHDRAWAL':
				newText = APP_TEXTS.CREDIT_WITHDRAWAL
				break;

			case 'COMMISSION':
				newText = APP_TEXTS.COMMISSION
				break;

			case 'TS_SUCCESS':
				newText = APP_TEXTS.SUCCESS_STATE;
				break;

			case 'TS_CLOSE':
				newText = APP_TEXTS.CLOSURE_STATE;
				break;

			case 'TS_CANCEL':
				newText = APP_TEXTS.CANCEL_STATE;
				break;

			default:
				newText = text;
				break;
		}

		return newText;
	};

	setMessage = (type, message) => {
		if (type && message) {
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	handleToDateChange = (e) => {
		this.setState({
			toDate: e,
		});
	}

	handleSelectType = (e) => {
		if (e.target.value) {
			this.setState({ posId: e.target.value });
		}
	};

	goToHome = () => {
		const path = '/';
		this.props.history.push(path);
	};

	setShowTotals = () => {
		this.setState({
			showTotals: true,
			showDetails: false,
			showBalance: false,
		});
	};

	setShowDetails = () => {
		this.setState({
			showDetails: true,
			showTotals: false,
			showBalance: false,
		});

		if (!this.state.serverData) {
			this.getTransactions();			
		}
	};

	setShowBalance = () => {
		this.setState({
			showBalance: true,
			showDetails: false,
			showTotals: false,
		});

		if (!this.state.serverData) {
			this.getTransactions();			
		}
	};

	showConfirmDialog = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ openDialogConfir: true });
	};

	handleDialogOk = () => {
		this.closeTransactionsRequest();
		this.setState({ openDialogConfir: false });
	};

	handleDialogCancel = () => {
		this.setState({ openDialogConfir: false });
		this.clearError();
	};

	exportToCSV = () => {
		if (this.state.detailsData.length) {
			const header = [];
			const data = this.state.detailsData;
			this.tableDetailsColumns.map((row) => header.push(row.label));

			ExportToCSV(header, data);
		}
	}

	async closeTransactionsRequest() {
		if (!this.state.arrayTransactions.length) {
			this.getTransactions();
			return;
		}
		
		this.setMessage('info', APP_TEXTS.CLOSING_TRANSACTIONS);

		// Get User Data
		this.userData = this.props.userData;

		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_CLOSE_TRANSACTION;
		const params = JSON.stringify({
			"date_to": this.state.toDate,
			"operator_id": this.userData.operatorId,
			"transaction_ids": this.state.arrayTransactions,
		});

		let headers = {};
		headers = new Headers({
			'Content-Type': 'application/json',
			'Authorization': `JWT ${this.userData.token}`,
		});

		await fetch(requestUrl, {
			method: 'POST',
			body: params,
			headers,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.props.history.push('/');
						break;

					default:
						// Another status, send unexpected error
						// this.setErrorMessageOnForm('error', APP_TEXTS.CONNECTION_ERROR);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then((resp) => {
				// Check status for errors
				if (resp.errors && resp.errors.length) {				
					// Get Error code
					const errorCode = resp.errors[0] && resp.errors[0].code ? resp.errors[0].code : 0;
					this.processErrors(errorCode);
					return;
				}

				if (resp.balance && resp.transactions) {
					this.setState({
						balanceData: resp.balance,
						disableCloseButton: true,
					});
					this.setShowBalance();
					this.setMessage('info', APP_TEXTS.CLOSED_TRANSACTIONS);
					
				} else if(resp.message) {
					this.setMessage('info', resp.message);
				} else if(resp.transactions && resp.transactions.length === 0) {
					this.setMessage('', APP_TEXTS.NOT_REPORT_FOUND);
					this.setState({ 
						serverData: [],
					 });
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	render() {
		const {
			balanceData,
			detailsData,
			disableCloseButton,
			openDialogConfir,
			pointsSale,
			posId,
			showBalance,
			showDetails,
			showTotals,
			toDate,
			totalsData,
		} = this.state;

		const dataTotalsTable = {
			tableColumns: this.tableTotalsColumns,
			rowData: totalsData,
		};
		const dataDetailsTable = {
			tableColumns: this.tableDetailsColumns,
			rowData: detailsData,
		};
		const dataBalanceTable = {
			tableColumns: this.tableBalanceColumns,
			rowData: balanceData,
		};

		return (
			<ThemeProvider theme={theme}>
				<Paper className="Main InternalPage CenteredX" variant="outlined" elevation={12}>
					<Grid container className="MainGrid h100">
						<Grid item xs={12} className="SectionTitle">
							<GridListTile key="1" className="Title">
								<Box
									className="CardImage"
									style={{
										backgroundImage: "url('./images/balances.jpg')",
									}}
								/>
								<GridListTileBar title="Cierre de Operaciones" titlePosition="top" />
							</GridListTile>
						</Grid>
						<Grid item xs={12} className="SectionBody">
							<Paper className="Body mh100" elevation={0}>
								<Grid container className="BodyTitle">
									<Grid
										item
										xs={12}
										style={{ display: 'flex', margin: '2% 0px 0px 0px' }}
									>
										<Grid
											item
											xs={4}
											style={{ margin: '0px 1%' }}
										>
											<FormControl 
												variant="outlined"
												fullWidth
											>
												<InputLabel id="labelType">Punto de Venta</InputLabel>
												<Select
													labelId="labelSelectType"
													id="selectType"
													value={posId}
													onChange={this.handleSelectType}
													label="Tipo de Operación."
												>
													{(pointsSale && pointsSale.length > 1) && 
														<MenuItem value="all">Todos</MenuItem>
													}
													{pointsSale && pointsSale.map((pos) => (
														<MenuItem value={pos.id}>{pos.name}</MenuItem>
													))
													}
												</Select>
											</FormControl>
										</Grid>
										<Grid
											item
											xs={3}
											justify='flex-end'
										>
											<MuiPickersUtilsProvider
												utils={DateFnsUtils}
												locale={es}
												fullWidth
											>
												<DatePicker
													fullWidth
													disableFuture
													autoOk
													inputVariant="outlined"
													label="Seleccione Hasta Fecha"
													format="dd/MM/yyyy"
													orientation="landscape"
													id="FromDate"
													okLabel=''
													cancelLabel=''
													emptyLabel=''
													invalidLabel=''
													invalidDateMessage=''
													value={toDate}
													onChange={this.handleToDateChange}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid
											item
											xs={5}
										>
											<Box style={{ margin: '2% 0px'}}>
												<Button
													onClick={this.getTransactions}
													className={
														classNames({
															Button,
															greenGradient: (posId > 0 || posId === 'all'),
															greyGradient: (posId === 0),
														})
													}
													disabled={(posId === 0)}
												>
													Ver Operaciones
												</Button>
												<Button
													className={
														classNames({
															Button,
															greenGradient: (!disableCloseButton),
															greyGradient: (disableCloseButton),
														})
													}
													disabled={(disableCloseButton)}
													onClick={this.showConfirmDialog}
												>
													Cerrar Operaciones
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</Paper>
							<Paper
								className="Body h100"
								elevation={0}
								style={{ margin: '1% 0%'}}
							>
								{(totalsData.length > 0) &&
									<Toolbar>
										<Box>
											<Button
												variant="contained"
												size="small"
												style={((showTotals) ? toolbarBtnSelected : toolbarBasicBtn)}
												onClick={this.setShowTotals}
												disabled={(totalsData.length === 0)}
											>
												Resumen
											</Button>
										</Box>
										<Box>
											<Button
												variant="contained"
												size="small"
												style={((showDetails) ? toolbarBtnSelected : toolbarBasicBtn)}
												onClick={this.setShowDetails}
												disabled={(detailsData.length === 0)}
											>
												Detalle
											</Button>
										</Box>
										<Box>
											<Button
												variant="contained"
												size="small"
												style={((showBalance) ? toolbarBtnSelected : toolbarBasicBtn)}
												onClick={this.setShowBalance}
												disabled={(balanceData === '')}
											>
												Balance
											</Button>
										</Box>
										<Box>
											<Button
												variant="contained"
												size="small"
												style={toolbarBasicBtn}
												onClick={this.exportToCSV}
												disabled={(detailsData.length === 0)}
											>
												Descargar CSV
											</Button>
										</Box>
									</Toolbar>
								}
								{(showTotals && totalsData.length > 0) && 
									/* Viewing totals data in the table */
									<TableTotals data={dataTotalsTable} />
								}
								{(showDetails && detailsData.length > 0) &&
									/* Viewing detailed data in the table */
									<TabletDetails data={dataDetailsTable} />
								}
								{(showBalance && balanceData !== '') && 
									/* Viewing totals data in the table */
									<TableTotals data={dataBalanceTable} />
								}
							</Paper>
						</Grid>
						<Grid item xs={12} className="SectionActions">
							<Paper className="Actions h100" elevation={0} style={bottomBar}>
								<Collapse
									in={this.state.error.message !== ''}
									style={{ width: '100%'}}
								>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
										style={{ padding: '0px 16px', margin: 'auto' }}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								<Button
									color="primary"
									className="Button ButtonHigh redGradient"
									onClick={this.goToHome}
								>
									{APP_TEXTS.GO_TO_MENU}
								</Button>
							</Paper>
						</Grid>
						<Dialog
							disableBackdropClick
							disableEscapeKeyDown
							maxWidth="xs"
							fullWidth
							aria-labelledby="confirmation-dialog-title"
							open={openDialogConfir}
						>
							<LinearProgress className="linear-progress-warning" />
							<DialogTitle style={{ backgroundColor: '#221f1f', color: '#ffffff', padding: '8%' }}>
								<b>{APP_TEXTS.TITLE_MESSAGE_CONFIRMATION}</b>
							</DialogTitle>
							<DialogContentText style={{ padding: '5%' }}>
								<Typography variant="h6" gutterBottom style={{ color: '#000000' }}>
									{APP_TEXTS.CONFIRM_CLOSE_TRANSACTIONS}
								</Typography>
							</DialogContentText>
							<DialogActions>
								<Button
									className="Button redGradient"
									autoFocus
									onClick={this.handleDialogCancel}
									color="default"
								>
									{APP_TEXTS.CANCEL}
								</Button>
								<Button
									className="Button greenGradient"
									onClick={this.handleDialogOk}
									color="default"
								>
									{APP_TEXTS.BTN_CLOSE}
								</Button>
							</DialogActions>
						</Dialog>
					</Grid>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

export default withRouter(connect(mapStateToProps)(CloseTransactions));
// export default Reports;
