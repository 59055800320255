/* eslint-disable no-useless-constructor */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

// User Profile
import React from 'react';
import {
	Button,
	TextField,
	Collapse,
	LinearProgress,
	Paper,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	CircularProgress,
	Grid,
	ThemeProvider,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import { setUserData, clearUserData } from '../redux/action_types';
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';
import { APP_UTILS } from '../config/app.utils';

// Definition
class ResetPassword extends React.Component {
	constructor(props) {
		super(props);

		// Define initial state
		this.initialState = {
			formProcess: false,
			tokenValidation: true,
			tokenInvalid: false,
			password: '',
			passwordConfirmation: '',
			token: this.props.match.params && this.props.match.params.token,
			error: {
				type: 'info',
				message: '',
			},
		};

		this.state = this.initialState;
		this.passwordInput = null;
		this.passwordConfirmationInput = null;
	}

	componentDidMount() {
		this.processForm();
	}

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'password':
				if (e.key === 'Enter' && this.state.passwordConfirmation !== '') {
					this.processForm();
				}
				break;

			case 'passwordConfirmation':
				if (e.key === 'Enter') {
					this.processForm();
				}
				break;

			default:
				break;
		}
	};

	handleContinue = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.processForm();
	};

	handleCancel = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.props.history.push('../login');
	};

	handleLogout = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		this.props.history.push('../login');
	};

	processError = (errorCode) => {
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}
	};

	processResult = (data) => {
		// Check status
		if (!data.status || data.status.toUpperCase() !== 'OK') {
			if (this.state.tokenValidation) {
				this.setState({ tokenInvalid: true, formProcess: false });
				return;
			}
			this.setMessage('error', APP_TEXTS.INVALID_DATA);
			this.setState({ formProcess: false });
			return;
		}

		if (this.state.tokenValidation && data.status.toUpperCase() === 'OK') {
			this.setState({
				tokenValidation: false,
				tokenInvalid: false,
				formProcess: false,
			});
			this.setMessage('info', APP_TEXTS.MESSAGE_PASSWORD_LENGTH);
			return;
		}

		this.processError(data.status);

		// Check for errors
		/* switch (data.status) {
			case '200':
				this.setMessage('error', APP_TEXTS.INVALID_DATA);
				break;

			case '300':
				this.setMessage('error', APP_TEXTS.INVALID_USER_PASSWORD);
				break;

			default:
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				break;
		} */

		// Set up data
		this.setState({
			formProcess: false,
		});

		// Clear data and close login
		this.setMessage('success', APP_TEXTS.CHANGE_PASSWORD_SUCCESSFUL);
		setTimeout(() => {
			this.props.history.push('../login');
		}, 1000);
	};

	setErrorMessageOnForm = (errorType, errorText) => {
		this.setMessage(errorType, errorText);
		this.setState({ formProcess: false });
	};

	setMessage = (type, message) => {
		if (type && message) {
			// Set Error
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	async processForm() {
		// Double check that the login process is not already running
		if (this.state.formProcess) {
			return false;
		}

		if (!this.state.tokenValidation) {
			// Read user and password
			if (!this.state.password) {
				this.passwordInput.focus();
				this.setMessage('info', APP_TEXTS.ENTER_NEW_PASSWORD);
				return false;
			}
			if (!this.state.passwordConfirmation) {
				this.passwordConfirmationInput.focus();
				this.setMessage('info', APP_TEXTS.ENTER_NEW_PASSWORD_CONFIRMATION);
				return false;
			}
			if (this.state.password !== this.state.passwordConfirmation) {
				this.passwordConfirmationInput.focus();
				this.setMessage('info', APP_TEXTS.PASSWORD_DO_NOT_MATCH);
				return false;
			}
		}

		// Set Form on process and clear previous error
		this.setState({
			formProcess: true,
			error: { type: 'info', message: '' },
		});

		// Get data
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += this.state.tokenValidation
			? APP_CONFIG.API_ENDPOINT_RESET_PASSWORD_VAL
			: APP_CONFIG.API_ENDPOINT_RESET_PASSWORD;
		requestUrl += `?hash=${new Date().getTime()}`;

		const data = new FormData();
		data.append('token', this.state.token);
		if (!this.state.tokenValidation) {
			data.append('password', this.state.password);
		}

		await fetch(requestUrl, {
			method: 'POST',
			body: data,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 404:
						// Token not found
						this.setState({
							tokenInvalid: true,
						});
						break;

					default:
						// Another status, send unexpected error
						this.setErrorMessageOnForm('error', APP_TEXTS.CONNECTION_ERROR);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then(
				(returnedData) => {
					this.processResult(returnedData);
				},
				() => {
					// Set Error
					this.setMessage('error', APP_TEXTS.CONNECTION_ERROR);
					this.setState({ formProcess: false });
				}
			)
			.catch(() => {
				// Check if autologin
				// Set Error
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
				this.setState({ formProcess: false });
			});

		return true;
	}

	// Logo out / clear all user data
	logoutUser() {
		// Clear User Data
		this.setState(this.initialState);

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		// Update store
		this.props.clearUserData();
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Paper
					className="Home Main CenteredX no-border"
					variant="elevation"
					elevation={0}
					style={{ backgroundColor: 'transparent' }}
				>
					<Card className="Card Login CenteredXY" variant="outlined" elevation={4}>
						<CardHeader
							title="Cambiar contraseña"
							subheader={APP_TEXTS.RESET_PASSWORD}
							avatar={<LockIcon />}
						/>
						{this.state.tokenValidation && (
							<>
								<CardContent>
									{!this.state.tokenInvalid && (
										<Grid container className="relative h100">
											<Grid
												item
												xs={2}
												className="relative h100 alignSelfCenter textAlignRight"
											>
												<CircularProgress color="secondary" />
											</Grid>
											<Grid
												item
												xs={10}
												className="relative h100 alignSelfCenter textAlignCenter"
											>
												{APP_TEXTS.VALIDATING_DATA}
											</Grid>
										</Grid>
									)}
									{this.state.tokenInvalid && (
										<Grid container className="relative h100">
											<Grid
												item
												xs={12}
												className="relative h100 alignSelfCenter"
											>
												<Alert className="Alert" severity="warning">
													{APP_TEXTS.PASSWORD_TOKEN_EXPIRED}
												</Alert>
											</Grid>
										</Grid>
									)}
								</CardContent>
								{this.state.tokenInvalid && (
									<CardActions>
										<Grid container className="relative h100">
											<Grid
												item
												xs={12}
												className="relative h100 alignSelfCenter textAlignRight"
											>
												<Button
													type="submit"
													onClick={this.handleCancel}
													color="primary"
													className="Button ButtonHigh redGradient"
												>
													{APP_TEXTS.BACK}
												</Button>
											</Grid>
										</Grid>
									</CardActions>
								)}
							</>
						)}
						{!this.state.tokenValidation && (
							<>
								<CardContent>
									<div>
										<TextField
											margin="dense"
											id="password"
											label="Nueva contraseña"
											type="password"
											variant="outlined"
											fullWidth
											value={this.state.password}
											inputRef={(input) => {
												this.passwordInput = input;
											}}
											onKeyPress={this.onKeyPress}
											onChange={(event) =>
												this.setState({
													password: event.currentTarget.value,
												})
											}
										/>
										<TextField
											margin="dense"
											id="passwordConfirmation"
											label="Confirmación"
											type="password"
											variant="outlined"
											fullWidth
											value={this.state.passwordConfirmation}
											inputRef={(input) => {
												this.passwordConfirmationInput = input;
											}}
											onKeyPress={this.onKeyPress}
											onChange={(event) =>
												this.setState({
													passwordConfirmation: event.currentTarget.value,
												})
											}
										/>
										<Collapse in={this.state.error.message !== ''}>
											<Alert
												className="Alert"
												onClose={this.clearError}
												severity={this.state.error.type}
											>
												{this.state.error.message}
											</Alert>
										</Collapse>
										{this.state.formProcess && <LinearProgress />}
									</div>
								</CardContent>
								<CardActions>
									<Grid container className="relative h100">
										<Grid
											item
											xs={12}
											className="relative h100 alignSelfCenter textAlignRight"
										>
											<Button
												type="submit"
												onClick={this.handleContinue}
												className="Button ButtonHigh greenGradient"
												disabled={this.state.formProcess}
											>
												{APP_TEXTS.MESSAGE_CHANGE_PASSWORD}
											</Button>
										</Grid>
									</Grid>
								</CardActions>
							</>
						)}
					</Card>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
