// React
import React from 'react';
// Redux and Router
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData, clearUserData } from './redux/action_types';
// Components
import Header from './Components/Header';
import Footer from './Components/Footer';
// View - Pages
import BuyCredits from './Pages/BuyCredits';
import BuyTickets from './Pages/BuyTickets';
import BuyTicketsPayment from './Pages/BuyTicketsPayment';
import Cashout from './Pages/Cashout';
import CashPrizes from './Pages/CashPrizes';
import ChangePassword from './Pages/ChangePassword';
import CloseTransactions from './Pages/CloseTransactions';
import ForgotPassword from './Pages/ForgotPassword';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Report from './Pages/Report';
import ResetPassword from './Pages/ResetPassword';
// CSS
import './css/general.css';
import './css/main.css';
import './css/animations.css';

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.Component {
	render() {
		// Check login state
		const { userData } = this.props;

		return (
			<Router basename="/">
				<Header />
				<div className="HeaderSpacer" />
				<div className="MainContent relative">
					<div className="Content">
						<Switch>
							<Route path="/login">
								{!userData.logged ? <Login /> : <Redirect to="/" />}
							</Route>
							<Route path="/forgotpassword">
								{!userData.logged ? <ForgotPassword /> : <Redirect to="/" />}
							</Route>
							<Route path="/changepassword" component={ChangePassword} />
							<Route path="/resetpassword/:token">
								{!userData.logged ? <ResetPassword /> : <Redirect to="/" />}
							</Route>
							<Route exact path="/updateuser">
								{!userData.logged ? <Login /> : <Redirect to="/" />}
							</Route>
							{!userData.logged
							&& (
								<Route path="/">
									<Redirect to="/login" />
								</Route>
							)}
							{userData.logged && userData.firstLogin && (
								<Route path="/">
									<Redirect to="/changepassword" />
								</Route>
							)}
							<Route exact path="/" component={Home} />
							<Route exact path="/tickets" component={BuyTickets} />
							<Route exact path="/tickets/:id" component={BuyTicketsPayment} />
							<Route exact path="/credits" component={BuyCredits} />
							<Route exact path="/prizes" component={CashPrizes} />
							<Route exact path="/report" component={Report} />
							<Route exact path="/CloseTransactions" component={CloseTransactions} />
							<Route exact path="/cashout" component={Cashout} />
							<Route path="/">
								<Redirect to="/" />
							</Route>
						</Switch>
					</div>
					<Footer />
				</div>
			</Router>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
