/* eslint-disable no-useless-constructor */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */

// User Profile
import React from 'react';
import {
	Button,
	TextField,
	Collapse,
	LinearProgress,
	Paper,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Grid,
	ThemeProvider,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import LockIcon from '@material-ui/icons/Lock';
import { connect } from 'react-redux';
import { setUserData, clearUserData } from '../redux/action_types';
import APP_CONFIG from '../config/app.config';
import { APP_TEXTS } from '../config/app.texts';
import theme from '../Components/ColorTheme';
import { APP_UTILS } from '../config/app.utils';

// Definition
class ForgotPassword extends React.Component {
	constructor(props) {
		super(props);

		// Define initial state
		this.initialState = {
			formInProcess: false,
			formCompleted: false,
			email: '',
			token: '',
			error: {
				type: 'info',
				message: '',
			},
		};

		this.state = this.initialState;
		this.email = '';
		this.emailInput = null;
	}

	onKeyPress = (e) => {
		// Get input field
		switch (e.target.id) {
			case 'email':
				if (e.key === 'Enter') {
					this.processForm();
				}
				break;

			default:
				break;
		}
	};

	handleContinue = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.processForm();
	};

	handleCancel = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ email: '' });
		this.props.history.push('/login');
	};

	handleAccept = (e) => {
		e.preventDefault();
		e.stopPropagation();
		const { token } = this.state;

		if (token === '') {
			this.setMessage('error', APP_TEXTS.REQUIRED_TOKEN_SENT);
			return;
		}

		this.props.history.push(`resetpassword/${token}`);
	};

	processResponse = (data) => {
		// Check status
		if (!data.status || data.status.toLowerCase() !== 'ok') {
			// Check for errors
			if (data.email) {
				this.setMessage('error', APP_TEXTS.INVALID_DATA);
			} else {
				this.setMessage('error', APP_TEXTS.UNEXPECTED_ERROR);
			}

			this.setState({ formInProcess: false });
			return;
		}

		// Set up data
		this.setState({
			formInProcess: false,
			formCompleted: true,
			// email: '',
		});

		// Clear data and close login
		this.setMessage('success', APP_TEXTS.EMAIL_TOKEN_SENT);
	};

	processErrors = (errorCode) => {
		// Check for errors
		const error = APP_UTILS.getErrorByCode(errorCode);
		if (error && error.type && error.message) {
			this.setMessage(error.type, error.message);	
		}

		this.setState({ formInProcess: false });
	};

	setErrorMessageOnForm = (errorType, errorText) => {
		// Set Error
		this.setState({
			error: { type: errorType, message: errorText },
			formInProcess: false,
		});
	};

	setMessage = (type, message) => {
		if (type && message) {
			// Set Error
			this.setState({
				error: { type, message },
			});
		}
	};

	clearError = () => {
		this.setState({
			error: { type: 'info', message: '' },
		});
	};

	async processForm() {
		// Double check that the login process is not already running
		if (this.state.formInProcess) {
			return false;
		}

		// Read user and password
		if (!this.state.email) {
			this.emailInput.focus();
			this.setMessage('warning', APP_TEXTS.ENTER_EMAIL);
			return false;
		}

		// Set Form on process and clear previous error
		this.setState({
			formInProcess: true,
			error: { type: 'info', message: '' },
		});

		// Get data
		let requestUrl = APP_CONFIG.API_ENDPOINT_URL;
		requestUrl += APP_CONFIG.API_ENDPOINT_FORGOT_PASSWORD;
		requestUrl += `?hash=${new Date().getTime()}`;

		const data = new FormData();
		this.email = this.state.email;
		data.append('email', this.email);
		await fetch(requestUrl, {
			method: 'POST',
			body: data,
		})
			.then((response) => {
				switch (response.status) {
					case 200:
					case 400:
						// VALID RESPONSE
						return response;

					case 401:
						// Unauthorized access
						this.setErrorMessageOnForm('error', APP_TEXTS.UNAUTHORIZED_ACCESS);
						break;

					default:
						// Another status, send unexpected error
						this.setErrorMessageOnForm('error', APP_TEXTS.CONNECTION_ERROR);
						break;
				}

				return false;
			})
			.then((response) => response.json())
			.then(
				(returnedData) => {
					this.processResponse(returnedData);
				},
				() => {
					this.setErrorMessageOnForm('error', APP_TEXTS.RETURNED_DATA_ERROR);
				}
			)
			.catch(() => {
				this.setErrorMessageOnForm('error', APP_TEXTS.UNEXPECTED_ERROR);
			});

		return true;
	}

	// Logo out / clear all user data
	logoutUser() {
		// Clear User Data
		this.setState(this.initialState);

		// Clear local storage
		localStorage.setItem(APP_CONFIG.API_USER_COOKIE, '');

		// Update store
		this.props.clearUserData();
	}

	render() {
		return (
			<ThemeProvider theme={theme}>
				<Paper
					className="Home Main CenteredX no-border"
					variant="elevation"
					elevation={0}
					style={{ backgroundColor: 'transparent' }}
				>
					<Card className="Card Login CenteredXY" variant="outlined" elevation={4}>
						<CardHeader
							title="Olvidé mi contraseña"
							subheader="Ingrese su correo electrónico para continuar"
							avatar={<LockIcon />}
						/>
						<CardContent style={{ paddingBottom: '10px' }}>
							<div>
								<TextField
									autoFocus
									required
									margin="dense"
									id="email"
									label="correo electrónico"
									type="text"
									variant="outlined"
									fullWidth
									value={this.state.email}
									inputRef={(input) => {
										this.emailInput = input;
									}}
									onKeyPress={this.onKeyPress}
									onChange={(event) =>
										this.setState({
											email: event.currentTarget.value,
										})
									}
									disabled={this.state.formCompleted}
								/>
								{this.state.formCompleted && (
									<TextField
										autoFocus
										required
										margin="dense"
										id="email"
										label="Token de Seguridad"
										type="text"
										variant="outlined"
										fullWidth
										value={this.state.token}
										onKeyPress={this.onKeyPress}
										onChange={(event) =>
											this.setState({
												token: event.currentTarget.value,
											})
										}
									/>
								)}
								<Collapse in={this.state.error.message !== ''}>
									<Alert
										className="Alert"
										onClose={this.clearError}
										severity={this.state.error.type}
									>
										{this.state.error.message}
									</Alert>
								</Collapse>
								{this.state.formInProcess && <LinearProgress />}
							</div>
							<CardActions>
								{!this.state.formCompleted && (
									<Grid
										container
										className="relative h100"
										style={{ paddingTop: '15px' }}
									>
										<Grid item xs={6} className="relative h100 alignSelfCenter">
											<Button
												type="button"
												onClick={this.handleCancel}
												className="Button ButtonHigh redGradient"
												disabled={this.state.formInProcess}
											>
												{APP_TEXTS.CANCEL}
											</Button>
										</Grid>
										<Grid
											item
											xs={6}
											className="relative h100 alignSelfCenter textAlignRight"
										>
											<Button
												type="submit"
												onClick={this.handleContinue}
												className="Button ButtonHigh greenGradient"
												disabled={this.state.formInProcess}
											>
												{APP_TEXTS.BTN_CONTINUE}
											</Button>
										</Grid>
									</Grid>
								)}
								{this.state.formCompleted && (
									<>
										<Button
											type="button"
											onClick={this.handleCancel}
											className="Button ButtonHigh redGradient"
											disabled={this.state.formInProcess}
										>
											{APP_TEXTS.CANCEL}
										</Button>
										<Button
											type="button"
											onClick={this.handleAccept}
											className="Button ButtonHigh greenGradient"
											disabled={this.state.formInProcess}
										>
											{APP_TEXTS.ACCEPT}
										</Button>
									</>
								)}
							</CardActions>
						</CardContent>
					</Card>
				</Paper>
			</ThemeProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	userData: state.userData,
});

const mapDispatchToProps = {
	setUserData,
	clearUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
